import api, {
    AssetAccount, assetAccountTypeDisplay
} from '@api';
import { DialogActions, DialogContent } from '@mui/material';
import {
    Button, CurrencyField, DateField, RoutedDialog, RoutedDialogProps, Switch, TextField,
    renderEnumOptions, replaceItemById, usePageMessage, useParams
} from '@tsp-ui/core';
import { LoanDetailPageParams } from '@views/loans/LoanDetailPage';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import styles from './AssetAccountDialog.module.scss';
import { AssetAccountsContext } from './AssetAccountsSection';


interface AssetAccountDialogParams {
    assetAccountId: string;
}

export function AssetAccountDialog(props: Omit<RoutedDialogProps, 'title' | 'children'>) {
    const { assetAccountId } = useParams<AssetAccountDialogParams>();
    const [ saveLoading, setSaveLoading ] = useState(false);
    const { accountID: clientId, loanID: loanId } = useParams<LoanDetailPageParams>();
    const pageMessage = usePageMessage();
    const navigate = useNavigate();

    const { assetAccounts, setAssetAccounts, setAssetSummary } = useContext(AssetAccountsContext);

    const assetAccountToEdit = assetAccounts.find(
        (account) => account.id === assetAccountId
    );

    const formMethods = useForm({
        defaultValues: assetAccountToEdit
    });

    const handleSubmit = formMethods.handleSubmit(async (formValues: AssetAccount) => {
        setSaveLoading(true);

        try {
            const assetAccount = assetAccountId
                ? await api.assetAccount.updateAssetAccount(clientId, loanId, assetAccountId, formValues)
                : await api.assetAccount.createAssetAccount(clientId, loanId, formValues);

            setAssetAccounts(
                assetAccountId
                    ? replaceItemById(assetAccounts, assetAccount)
                    : [ ...assetAccounts, assetAccount ]
            );

            // refresh asset summary
            setAssetSummary(await api.assetAccount.getAssetSummary(clientId, loanId));

            navigate('..');

            pageMessage.success('Asset account saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving asset account', error);
        }

        setSaveLoading(false);
    });

    return (
        <RoutedDialog
            {...props}
            title={`${!assetAccountId ? 'Add' : 'Edit'} asset account`}
            maxWidth="md"
            saveLoading={saveLoading}
        >
            <DialogContent>
                <form
                    id="asset-account-form"
                    noValidate
                    className={styles.root}
                    onSubmit={handleSubmit}
                >
                    <FormProvider {...formMethods}>
                        <TextField<AssetAccount>
                            name="institutionName"
                            label="Institution name"
                            required
                        />

                        <TextField<AssetAccount>
                            name="accountIdentifier"
                            label="Account identifier"
                            required
                        />

                        <CurrencyField<AssetAccount>
                            name="accountBalance"
                            label="Account balance"
                            type="number"
                        />

                        <CurrencyField<AssetAccount>
                            name="qualifyingBalance"
                            label="Qualifying balance"
                            type="number"
                        />

                        <TextField<AssetAccount>
                            name="type"
                            label="Type"
                            select
                        >
                            {renderEnumOptions(assetAccountTypeDisplay)}
                        </TextField>

                        <TextField<AssetAccount>
                            name="statementMonths"
                            label="Statement months"
                            type="number"
                        />

                        <DateField<AssetAccount>
                            name="statementDate"
                            label="Statement date"
                            type="date"
                            required
                        />

                        <Switch<AssetAccount>
                            name="isReviewed"
                            label="Reviewed"
                        />
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions>
                <Button
                    type="submit"
                    variant="contained"
                    form="asset-account-form"
                    disabled={saveLoading}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}
