import api, {
    AssetAccount, AssetCategory, AssetSummary, PermissionType, getAssetCategory
} from '@api';
import { Check, Close, WarningAmber } from '@mui/icons-material';
import {
    Button, Link as MuiLink, Paper, Tooltip, Typography
} from '@mui/material';
import {
    formatCurrency, formatDate, useAsyncEffect, useParams
} from '@tsp-ui/core';
import {
    LabelGroup, LabeledValue, ProgressIndicator, RoutedDialogManager, usePageMessage
} from '@tsp-ui/core/components';
import { withAuth } from '@utils';
import { LoanDetailPageParams } from '@views/loans/LoanDetailPage';
import {
    Dispatch, SetStateAction, createContext, useCallback, useState
} from 'react';
import { Link } from 'react-router-dom';

import AssetAccountCard from './AssetAccountCard';
import { AssetAccountDialog } from './AssetAccountDialog';
import styles from './AssetAccountsSection.module.scss';
import { SummaryLabeledValue } from './LiabilitiesSection';


export const AssetAccountsContext = createContext<{
    assetAccounts: AssetAccount[];
    setAssetAccounts: Dispatch<SetStateAction<AssetAccount[]>>;
    assetSummary: AssetSummary;
    setAssetSummary: Dispatch<SetStateAction<AssetSummary>>;
}>({
    assetAccounts: [],
    setAssetAccounts: () => {},
    assetSummary: {},
    setAssetSummary: () => {}
});

export function AssetAccountSection() {
    const [ assetAccounts, setAssetAccounts ] = useState<AssetAccount[]>([]);
    const [ assetSummary, setAssetSummary ] = useState<AssetSummary>({});
    const [ loading, setLoading ] = useState(true);
    const { accountID: clientId, loanID: loanId } = useParams<LoanDetailPageParams>();
    const pageMessage = usePageMessage();

    useAsyncEffect(useCallback(async () => {
        if (!clientId || !loanId) {
            return;
        }

        setLoading(true);
        try {
            const [ accounts, summary ] = await Promise.all([
                api.assetAccount.getAssetAccounts(clientId, loanId),
                api.assetAccount.getAssetSummary(clientId, loanId)
            ]);
            setAssetAccounts(accounts);
            setAssetSummary(summary);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching asset accounts', error);
        } finally {
            setLoading(false);
        }
    }, [
        clientId, loanId, pageMessage
    ]));

    const accountsNeedingReview = assetAccounts.filter(account => !account.isReviewed);
    const investmentAccounts = assetAccounts.filter(
        account => getAssetCategory(account.type) === AssetCategory.INVESTMENT && account.isReviewed
    );
    const liquidAccounts = assetAccounts.filter(
        account =>  getAssetCategory(account.type) === AssetCategory.LIQUID && account.isReviewed
    );

    const sufficientLiquidAssets = (assetSummary.totalAssetsRequired || 0)
        <= (assetSummary.liquidAssetsAvailable || 0);
    const sufficientAssets = (assetSummary.totalAssetsRequired || 0)
        <= ((assetSummary.liquidAssetsAvailable || 0) + (assetSummary.otherAssetsAvailable || 0));

    return loading ? <ProgressIndicator /> : (
        <AssetAccountsContext.Provider value={{
            assetAccounts,
            setAssetAccounts,
            assetSummary,
            setAssetSummary
        }}
        >
            <Button
                component={Link}
                to="asset-account"
            >
                Add Account
            </Button>

            <div className={styles.root}>
                <div className={styles.accounts}>
                    {!!accountsNeedingReview.length && (
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            className={styles.sectionLabel}
                        >
                            Needs review
                        </Typography>
                    )}

                    {accountsNeedingReview.map(account => <AssetAccountCard assetAccount={account} />)}

                    {!!liquidAccounts.length && (
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            className={styles.sectionLabel}
                        >
                            Liquid assets
                        </Typography>
                    )}

                    {liquidAccounts.map(account => (
                        <AssetAccountCard assetAccount={account} />
                    ))}

                    {!!investmentAccounts.length && (
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            className={styles.sectionLabel}
                        >
                            Stocks and retirement
                        </Typography>
                    )}

                    {investmentAccounts.map(account => (
                        <AssetAccountCard assetAccount={account} />
                    ))}

                    {!accountsNeedingReview.length
                        && !liquidAccounts.length
                        && !investmentAccounts.length && (
                        <Typography
                            variant="body2"
                            color="textSecondary"
                        >
                            No asset accounts found.&nbsp;
                            <MuiLink
                                component={Link}
                                to="asset-account"
                            >
                                Click here
                            </MuiLink> to add a new one.
                        </Typography>
                    )}
                </div>

                <div className={styles.summaryContainer}>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        Asset summary
                    </Typography>

                    <Paper
                        variant="outlined"
                        className={styles.summary}
                    >
                        <LabelGroup>
                            <SummaryLabeledValue
                                label="Original cash to close"
                                value={formatCurrency(assetSummary.cashToClose)}
                                plus
                            />

                            <SummaryLabeledValue
                                label="Gifts received at closing"
                                value={formatCurrency(assetSummary.giftsReceivedAtClosing)}
                                minus
                            />

                            <SummaryLabeledValue
                                label="Required reserves"
                                value={formatCurrency(assetSummary.requiredReserves)}
                                plus
                            />

                            <SummaryLabeledValue
                                label="Total assets required"
                                value={formatCurrency(assetSummary.totalAssetsRequired)}
                                bold
                            />

                            <SummaryLabeledValue
                                label="Liquid assets"
                                value={formatCurrency(assetSummary.liquidAssets)}
                                plus
                                className={styles.summaryExtraMargin}
                            />

                            <SummaryLabeledValue
                                label="Gifts not yet deposited"
                                value={formatCurrency(assetSummary.giftsNotYetDeposited)}
                                plus
                            />

                            <SummaryLabeledValue
                                label="Net equity from sale"
                                value={formatCurrency(assetSummary.netEquityFromSale)}
                                plus
                            />

                            <SummaryLabeledValue
                                label="Liquid assets available"
                                value={formatCurrency(assetSummary.liquidAssetsAvailable)}
                                bold
                            />

                            <SummaryLabeledValue
                                label="Other assets available"
                                value={formatCurrency(assetSummary.otherAssetsAvailable)}
                                bold
                                className={styles.summaryExtraMargin}
                            />
                        </LabelGroup>

                        <div className={styles.summaryButtonContainer}>
                            <LabeledValue
                                {...labeledValueProps}
                                label="Asset expiration"
                                // TODO: Add expiration date
                                value={formatDate(
                                    // 3 months in future
                                    new Date(new Date().setMonth(new Date().getMonth() + 3)).toISOString()
                                )}
                            />

                            <LabeledValue
                                {...labeledValueProps}
                                label="Sufficient assets"
                                value={sufficientLiquidAssets ? (
                                    <Check color="success" />
                                ) : sufficientAssets ? (
                                    <Tooltip
                                        title={`Insufficient liquid assets. Liquidation of other assets
                                         required to meet asset requirements.`}
                                    >
                                        <WarningAmber color="warning" />
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Insufficient assets. File will be suspended.">
                                        <Close color="error" />
                                    </Tooltip>
                                )}
                                className={styles.sufficientCash}
                            />

                            {!sufficientLiquidAssets && (
                                <LabeledValue
                                    {...labeledValueProps}
                                    label="Shortage"
                                    value={formatCurrency(
                                        (assetSummary.totalAssetsRequired || 0)
                                        - (assetSummary.liquidAssetsAvailable || 0)
                                        - (assetSummary.otherAssetsAvailable || 0)
                                    )}
                                />
                            )}
                        </div>
                    </Paper>
                </div>
            </div>

            <RoutedDialogManager routes={dialogRoutes} />
        </AssetAccountsContext.Provider>
    );
}

const dialogRoutes = {
    'asset-account': withAuth(AssetAccountDialog, [ PermissionType.MANAGE_ASSET_ACCOUNTS ]),
    'asset-account/:assetAccountId': withAuth(AssetAccountDialog, [ PermissionType.MANAGE_ASSET_ACCOUNTS ])
};

const labeledValueProps = {
    classNames: {
        label: styles.centerAlign,
        value: styles.centerAlign
    },
    variant: 'vertical'
} as const;
