import { apiUtils, getLoanBaseUrl } from '../api-utils';


export function getAssetAccounts(clientId: string, loanId: string): Promise<AssetAccount[]> {
    return apiUtils.get(`${getLoanBaseUrl(clientId, loanId)}/asset-account`);
}

export function getAssetSummary(clientId: string, loanId: string): Promise<AssetSummary> {
    return apiUtils.get(`${getLoanBaseUrl(clientId, loanId)}/asset-account/summary`);
}

export function createAssetAccount(
    clientId: string, loanId: string, assetAccount: AssetAccount
): Promise<AssetAccount> {
    return apiUtils.post(`${getLoanBaseUrl(clientId, loanId)}/asset-account`, assetAccount);
}

export function updateAssetAccount(
    clientId: string, loanId: string, assetAccountId: string, assetAccount: AssetAccount
): Promise<AssetAccount> {
    return apiUtils.put(`${getLoanBaseUrl(clientId, loanId)}/asset-account/${assetAccountId}`, assetAccount);
}

export function deleteAssetAccount(clientId: string, loanId: string, assetAccountId: string): Promise<void> {
    return apiUtils.delete(`${getLoanBaseUrl(clientId, loanId)}/asset-account/${assetAccountId}`);
}

export enum AssetAccountType {
  CHECKING_ACCOUNT = 'CHECKING_ACCOUNT',
  SAVINGS_ACCOUNT = 'SAVINGS_ACCOUNT',
  MUTUAL_FUND = 'MUTUAL_FUND',
  STOCK = 'STOCK',
  MONEY_MARKET_FUND = 'MONEY_MARKET_FUND',
  CERTIFICATE_OF_DEPOSIT = 'CERTIFICATE_OF_DEPOSIT',
  STOCK_OPTIONS = 'STOCK_OPTIONS',
  BOND = 'BOND',
  RETIREMENT_FUND = 'RETIREMENT_FUND',
  BRIDGE_LOAN_NOT_DEPOSITED = 'BRIDGE_LOAN_NOT_DEPOSITED',
  INDIVIDUAL_DEVELOPMENT_ACCOUNT = 'INDIVIDUAL_DEVELOPMENT_ACCOUNT',
  LIFE_INSURANCE = 'LIFE_INSURANCE',
  TRUST_ACCOUNT = 'TRUST_ACCOUNT'
}

export const assetAccountTypeDisplay: Record<AssetAccountType, string> = {
    [AssetAccountType.CHECKING_ACCOUNT]: 'Checking account',
    [AssetAccountType.SAVINGS_ACCOUNT]: 'Savings account',
    [AssetAccountType.MUTUAL_FUND]: 'Mutual fund',
    [AssetAccountType.STOCK]: 'Stock',
    [AssetAccountType.MONEY_MARKET_FUND]: 'Money market fund',
    [AssetAccountType.CERTIFICATE_OF_DEPOSIT]: 'Certificate of deposit',
    [AssetAccountType.STOCK_OPTIONS]: 'Stock options',
    [AssetAccountType.BOND]: 'Bond',
    [AssetAccountType.RETIREMENT_FUND]: 'Retirement fund',
    [AssetAccountType.BRIDGE_LOAN_NOT_DEPOSITED]: 'Bridge loan not deposited',
    [AssetAccountType.INDIVIDUAL_DEVELOPMENT_ACCOUNT]: 'Individual development account',
    [AssetAccountType.LIFE_INSURANCE]: 'Life insurance',
    [AssetAccountType.TRUST_ACCOUNT]: 'Trust account'
};

export enum AssetCategory {
    LIQUID = 'LIQUID',
    INVESTMENT = 'INVESTMENT',
    OTHER = 'OTHER'
}

export const ASSET_TYPE_CATEGORIES: Record<AssetAccountType, AssetCategory> = {
    [AssetAccountType.CHECKING_ACCOUNT]: AssetCategory.LIQUID,
    [AssetAccountType.SAVINGS_ACCOUNT]: AssetCategory.LIQUID,
    [AssetAccountType.MONEY_MARKET_FUND]: AssetCategory.LIQUID,
    [AssetAccountType.CERTIFICATE_OF_DEPOSIT]: AssetCategory.LIQUID,
    [AssetAccountType.INDIVIDUAL_DEVELOPMENT_ACCOUNT]: AssetCategory.LIQUID,

    [AssetAccountType.STOCK]: AssetCategory.INVESTMENT,
    [AssetAccountType.STOCK_OPTIONS]: AssetCategory.INVESTMENT,
    [AssetAccountType.MUTUAL_FUND]: AssetCategory.INVESTMENT,
    [AssetAccountType.RETIREMENT_FUND]: AssetCategory.INVESTMENT,

    [AssetAccountType.BOND]: AssetCategory.OTHER,
    [AssetAccountType.BRIDGE_LOAN_NOT_DEPOSITED]: AssetCategory.OTHER,
    [AssetAccountType.LIFE_INSURANCE]: AssetCategory.OTHER,
    [AssetAccountType.TRUST_ACCOUNT]: AssetCategory.OTHER
};

export function getAssetCategory(type?: AssetAccountType): AssetCategory | undefined {
    return type ? ASSET_TYPE_CATEGORIES[type] : undefined;
}

export const CATEGORY_LABELS: Record<AssetCategory, string> = {
    [AssetCategory.LIQUID]: 'Liquid Assets',
    [AssetCategory.INVESTMENT]: 'Investment & Retirement Accounts',
    [AssetCategory.OTHER]: 'Other Assets'
};

export interface AssetAccount {
    id: string;
    underwritingInstanceId?: string;
    entityId?: string;
    institutionName?: string;
    accountIdentifier?: string;
    accountBalance?: number;
    qualifyingBalance?: number;
    type?: AssetAccountType;
    statementMonths?: number;
    statementDate: string; // ISO date string
    isReviewed: boolean;
}

export interface AssetSummary {
    underwritingInstanceId?: string;
    cashToClose?: number;
    giftsReceivedAtClosing?: number;
    requiredReserves?: number;
    totalAssetsRequired?: number;
    liquidAssets?: number;
    giftsNotYetDeposited?: number;
    netEquityFromSale?: number;
    liquidAssetsAvailable?: number;
    otherAssetsAvailable?: number;
}
