/* eslint-disable max-len */
import { rest } from 'msw';

import { getMockUrl } from '../../mocks/getMockUrl';

import {
    getIncrementedUnderwritingCategoryId, getIncrementedUnderwritingStepId, setUnderwritingCategories, setUnderwritingStepConfigs, underwritingCategories, underwritingStepConfigs
} from './underwriting-mock-data';
import {
    UnderwritingCategoryConfig, UnderwritingStepConfig
} from './underwriting-types';


export const mocks = [
    rest.get(getMockUrl('/client/:clientId/underwriting-category'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json([ ...underwritingCategories ].sort((a, b) => a.displayOrder - b.displayOrder))
    ))),
    rest.get(getMockUrl('/client/:clientId/underwriting-category/:underwritingCategoryId/underwriting-step/:underwritingStepId'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(getUnderwritingStepById(req.params.underwritingStepId as string))
    ))),
    rest.put(getMockUrl('/client/:clientId/underwriting-category/:underwritingCategoryId/underwriting-step/:underwritingStepId'), (req, res, ctx) => {
        const underwritingStep = req.body as UnderwritingStepConfig;
        const index = underwritingStepConfigs.findIndex(step => step.id === req.params.underwritingStepId);
        underwritingStepConfigs[index] = underwritingStep;

        return (res(
            ctx.status(200),
            ctx.json(underwritingStep)
        ));
    }),
    rest.put(getMockUrl('/client/:clientId/underwriting-category/:underwritingCategoryConfigId'), async (req, res, ctx) => {
        const updatedConfig = await req.json() as UnderwritingCategoryConfig;
        const oldConfig = underwritingCategories.find(c => c.id === updatedConfig.id);

        if (!oldConfig) {
            return res(ctx.status(404));
        }

        // Handle reordering
        if (updatedConfig.displayOrder !== oldConfig.displayOrder) {
            const isMovingUp = updatedConfig.displayOrder < oldConfig.displayOrder;

            setUnderwritingCategories(underwritingCategories.map(config => {
                if (config.id === updatedConfig.id) {
                    return updatedConfig;
                }

                if (isMovingUp) {
                    if (config.displayOrder >= updatedConfig.displayOrder
                        && config.displayOrder < oldConfig.displayOrder) {
                        return {
                            ...config,
                            displayOrder: config.displayOrder + 1
                        };
                    }
                } else if (config.displayOrder <= updatedConfig.displayOrder
                        && config.displayOrder > oldConfig.displayOrder) {
                    return {
                        ...config,
                        displayOrder: config.displayOrder - 1
                    };
                }

                return config;
            }));
        } else {
            // Regular update without reordering
            const index = underwritingCategories.findIndex(c => c.id === updatedConfig.id);
            underwritingCategories[index] = updatedConfig;
        }

        return res(
            ctx.status(200),
            ctx.json(updatedConfig)
        );
    }),
    rest.post(getMockUrl('/client/:clientId/underwriting-category'), async (req, res, ctx) => {
        const newConfig = await req.json() as Omit<UnderwritingCategoryConfig, 'id'>;

        const newCategory: UnderwritingCategoryConfig = {
            id: getIncrementedUnderwritingCategoryId(),
            name: newConfig.name,
            displayOrder: (underwritingCategories.length + 1),
            steps: []
        };

        underwritingCategories.push(newCategory);

        return res(
            ctx.status(200),
            ctx.json(newCategory)
        );
    }),
    rest.delete(getMockUrl('/client/:clientId/underwriting-category/:underwritingCategoryConfigId'), (req, res, ctx) => {
        const underwritingCategoryConfigId = req.params.underwritingCategoryConfigId as string;
        const index = underwritingCategories.findIndex(c => c.id === underwritingCategoryConfigId);
        if (index === -1) {
            return res(ctx.status(404));
        }
        underwritingCategories.splice(index, 1);
        return res(ctx.status(200));
    }),
    rest.get(getMockUrl('/client/:clientId/underwriting-category/:underwritingCategoryConfigId/step-config'), (req, res, ctx) => {
        const underwritingCategoryConfigId = req.params.underwritingCategoryConfigId as string;
        const categoryConfig = underwritingCategories.find(c => c.id === underwritingCategoryConfigId);

        if (!categoryConfig) {
            return res(ctx.status(404));
        }

        return res(
            ctx.status(200),
            ctx.json(getUnderwritingStepsMockHandler(categoryConfig))
        );
    }),
    rest.post(getMockUrl('/client/:clientId/underwriting-category/:underwritingCategoryConfigId/step-config'), async (req, res, ctx) => {
        const { name } = await req.json();
        const underwritingCategoryConfigId = req.params.underwritingCategoryConfigId as string;
        const underWritingCategoryConfig = underwritingCategories.find(c => c.id === underwritingCategoryConfigId);

        if (!underWritingCategoryConfig) {
            return res(ctx.status(404));
        }

        return res(
            ctx.status(200),
            ctx.json(createUnderwritingStepMockHandler(underWritingCategoryConfig, name))
        );
    }),
    rest.get(getMockUrl('/client/:clientId/loan/:loanId/underwriting-data'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json({})
    ))
    // tlTODO make these work
    // rest.put(getMockUrl('/client/:clientId/underwriting-category/:underwritingCategoryConfigId/step-config/:underwritingStepConfigId'), async (req, res, ctx) => {
    //     const underWritingCategoryConfig = underwritingCategories
    //         .find(c => c.id === req.params.underwritingCategoryConfigId as string);
    //     const updatedStepConfig = await req.json() as UnderwritingStepConfig;
    //     const oldStepConfig = underWritingCategoryConfig?.stepConfigs.find(s => s.id === updatedStepConfig.id);

    //     if (!oldStepConfig || !underWritingCategoryConfig) {
    //         return res(ctx.status(404));
    //     }

    //     return res(
    //         ctx.status(200),
    //         ctx.json(updateUnderwritingStepConfigMockHandler(underWritingCategoryConfig, updatedStepConfig, oldStepConfig))
    //     );
    // }),
    // rest.delete(getMockUrl('/client/:clientId/underwriting-category/:underwritingCategoryConfigId/step-config/:underwritingStepConfigId'), (req, res, ctx) => {
    //     const underwritingCategoryConfigId = req.params.underwritingCategoryConfigId as string;
    //     const underwritingStepConfigId = req.params.underwritingStepConfigId as string;
    //     const underWritingCategoryConfig = underwritingCategories.find(c => c.id === underwritingCategoryConfigId);

    //     if (!underWritingCategoryConfig) {
    //         return res(ctx.status(404));
    //     }

    //     const stepIndex = underWritingCategoryConfig.stepConfigs.findIndex(s => s.id === underwritingStepConfigId);
    //     if (stepIndex === -1) {
    //         return res(ctx.status(404));
    //     }

    //     return res(
    //         ctx.status(200),
    //         ctx.json(deleteUnderwritingStepConfigMockHandler(underWritingCategoryConfig, underwritingStepConfigId))
    //     );
    // }),
    // rest.get(getMockUrl('/client/:clientId/underwriting-category/:underwritingCategoryConfigId/step-config/:underwritingStepConfigId/question-config'), (req, res, ctx) => {
    //     const underwritingCategoryConfigId = req.params.underwritingCategoryConfigId as string;
    //     const underwritingStepConfigId = req.params.underwritingStepConfigId as string;

    //     const category = underwritingCategories.find(c => c.id === underwritingCategoryConfigId);
    //     const stepConfig = category?.stepConfigs.find(s => s.id === underwritingStepConfigId);

    //     if (!category || !stepConfig) {
    //         return res(ctx.status(404));
    //     }

    //     return res(
    //         ctx.status(200),
    //         ctx.json(stepConfig.questionConfigs)
    //     );
    // }),
    // rest.post(getMockUrl('/client/:clientId/underwriting-category/:underwritingCategoryConfigId/step-config/:underwritingStepConfigId/question-config'), async (req, res, ctx) => {
    //     const { description, type } = await req.json();
    //     const underwritingCategoryConfigId = req.params.underwritingCategoryConfigId as string;
    //     const underwritingStepConfigId = req.params.underwritingStepConfigId as string;

    //     const category = underwritingCategories.find(c => c.id === underwritingCategoryConfigId);
    //     const stepConfig = category?.stepConfigs.find(s => s.id === underwritingStepConfigId);

    //     if (!category || !stepConfig) {
    //         return res(ctx.status(404));
    //     }

    //     const newQuestionConfig = {
    //         id: getIncrementedUnderwritingQuestionConfigId(),
    //         description,
    //         type
    //     };

    //     stepConfig.questionConfigs.push(newQuestionConfig);

    //     return res(
    //         ctx.status(200),
    //         ctx.json(newQuestionConfig)
    //     );
    // }),
    // rest.put(getMockUrl('/client/:clientId/underwriting-category/:underwritingCategoryConfigId/step-config/:underwritingStepConfigId/question-config/:underwritingQuestionConfigId'), async (req, res, ctx) => {
    //     const updatedQuestionConfig = await req.json() as UnderwritingQuestionConfig;
    //     const underwritingCategoryConfigId = req.params.underwritingCategoryConfigId as string;
    //     const underwritingStepConfigId = req.params.underwritingStepConfigId as string;

    //     const category = underwritingCategories.find(c => c.id === underwritingCategoryConfigId);
    //     const stepConfig = category?.stepConfigs.find(s => s.id === underwritingStepConfigId);
    //     const oldQuestion = stepConfig?.questionConfigs.find(q => q.id === updatedQuestionConfig.id);

    //     if (!category || !stepConfig || !oldQuestion) {
    //         return res(ctx.status(404));
    //     }

    //     return res(
    //         ctx.status(200),
    //         ctx.json(updatedQuestionConfig)
    //     );
    // }),
    //     rest.delete(getMockUrl('/client/:clientId/underwriting-category/:underwritingCategoryConfigId/step-config/:underwritingStepConfigId/question-config/:underwritingQuestionConfigId'), (req, res, ctx) => {
    //         const underwritingCategoryConfigId = req.params.underwritingCategoryConfigId as string;
    //         const underwritingStepConfigId = req.params.underwritingStepConfigId as string;
    //         const underwritingQuestionConfigId = req.params.underwritingQuestionConfigId as string;

    //         const category = underwritingCategories.find(c => c.id === underwritingCategoryConfigId);
    //         const stepConfig = category?.stepConfigs.find(s => s.id === underwritingStepConfigId);

    //         if (!category || !stepConfig) {
    //             return res(ctx.status(404));
    //         }

    //         const questionIndex = stepConfig.questionConfigs.findIndex(q => q.id === underwritingQuestionConfigId);
    //         if (questionIndex === -1) {
    //             return res(ctx.status(404));
    //         }

    //         stepConfig.questionConfigs.splice(questionIndex, 1);

    //     return res(ctx.status(200));
    // })
];

export function getUnderwritingStepsMockHandler(underWritingCategoryConfig: UnderwritingCategoryConfig): UnderwritingStepConfig[] {
    const underwritingStepsToReturn = [ ...underwritingStepConfigs ].filter(stepConfig => stepConfig.underwritingCategoryId === underWritingCategoryConfig.id).sort((a, b) => a.displayOrder - b.displayOrder);

    return underwritingStepsToReturn;
}

export function createUnderwritingStepMockHandler(
    underWritingCategoryConfig: UnderwritingCategoryConfig, name: string
): UnderwritingStepConfig {
    const newStepConfig: UnderwritingStepConfig = {
        id: getIncrementedUnderwritingStepId(),
        underwritingCategoryId: underWritingCategoryConfig.id,
        name,
        questionCount: 0,
        displayOrder: getUnderwritingStepsMockHandler(underWritingCategoryConfig).length + 1
    };

    underwritingStepConfigs.push(newStepConfig);

    return newStepConfig;
}

export function updateUnderwritingStepConfigMockHandler(
    underWritingCategoryConfig: UnderwritingCategoryConfig,
    updatedStepConfig: UnderwritingStepConfig,
    oldStepConfig: UnderwritingStepConfig
): UnderwritingStepConfig {
    if (oldStepConfig.displayOrder !== updatedStepConfig.displayOrder) {
        // Find the step at the target displayOrder
        const targetIndex = underwritingStepConfigs.findIndex(
            config => config.underwritingCategoryId === updatedStepConfig.underwritingCategoryId
             && config.displayOrder === updatedStepConfig.displayOrder
        );

        underwritingStepConfigs[targetIndex] = {
            ...underwritingStepConfigs[targetIndex],
            displayOrder: oldStepConfig.displayOrder
        };
    }

    const currentIndex = underwritingStepConfigs.findIndex(
        config => config.underwritingCategoryId === updatedStepConfig.underwritingCategoryId && config.id === updatedStepConfig.id
    );
    underwritingStepConfigs[currentIndex] = updatedStepConfig;

    return updatedStepConfig;
}

export function deleteUnderwritingStepConfigMockHandler(underWritingCategoryConfig: UnderwritingCategoryConfig, underwritingStepConfigId: string) {
    const stepIndex = underwritingStepConfigs.findIndex(s => s.underwritingCategoryId === underWritingCategoryConfig.id && s.id === underwritingStepConfigId);
    const deletedStep = underwritingStepConfigs[stepIndex];
    underwritingStepConfigs.splice(stepIndex, 1);

    // Reorder remaining stepConfigs
    setUnderwritingStepConfigs(underwritingStepConfigs.map(step => ({
        ...step,
        displayOrder: step.displayOrder > deletedStep.displayOrder
            ? step.displayOrder - 1
            : step.displayOrder
    })));
}

function getUnderwritingStepById(underwritingStepId: string): UnderwritingStepConfig | undefined {
    return underwritingStepConfigs.find(step => step.id === underwritingStepId);
}
