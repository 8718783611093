import api, { AuthResult, PermissionType } from '@api';
import { EXPIRATION_DATE_OFFSET, createApiUtils, getCognitoToken } from '@tsp-ui/core/utils';
import { isAfter } from 'date-fns';
import decodeJWT from 'jwt-decode';


let clientTokenPromise: Promise<AuthResult> | null = null;
let clientToken: string;
let onActiveTokenChange: (newToken: string) => void;

export const apiUtils = {
    ...createApiUtils({
        getAuthToken,
        getRequestUrl: (url, options, config) => config?.apiUrl + url
    }),

    clearClientToken() {
        clientToken = '';
    },

    setOnActiveTokenChange(newOnActiveTokenChange: (newToken: string) => void) {
        onActiveTokenChange = newOnActiveTokenChange;
    }
};

/**
 * Internal users use cognito token. Client/Customer users use token from `/auth/client/${clientID}`.
 */
export async function getAuthToken(url?: string): Promise<string> {
    let newAuthToken;

    if (!clientToken || url?.includes('/auth/client')) {
        newAuthToken = await getCognitoToken();
        if (!clientToken) {
            onActiveTokenChange(newAuthToken);
        }
    } else {
        const {
            exp, client_id: clientID, customer_id: customerID
        } = decodeAuthToken<ClientTokenPayload>(clientToken);

        const expiration = new Date(exp * 1000);

        newAuthToken = isAfter(new Date(Date.now() + EXPIRATION_DATE_OFFSET), expiration)
            ? await refreshClientToken(clientID, customerID)
            : clientToken;

        onActiveTokenChange(newAuthToken);
    }

    return newAuthToken;
}

export async function refreshClientToken(clientID: string, customerID?: string) {
    if (!clientTokenPromise) {
        clientTokenPromise = api.auth.fetchClientToken(clientID, customerID);
    }

    try {
        const { tokenType = 'Bearer', accessToken } = await clientTokenPromise;
        clientToken = `${tokenType} ${accessToken}`;
    } finally {
        clientTokenPromise = null;
    }

    return clientToken;
}

// Final shape TBD, aside from `permission` prop
export interface ClientTokenPayload {
    permission: PermissionType[];
    sub: string;
    user_id: string;
    client_id: string;
    customer_id?: string;
    email: string;
    exp: number;
    iss: string;
    aud: string;
    given_name: string;
    family_name: string;
}

// inspected from the return of getCognitoToken()
export interface InternalTokenPayload {
    client_id: string;
    sub: string;
    iss: string;
    origin_jti: string;
    event_id: string;
    token_use: string;
    scope: string;
    auth_time: number;
    exp: number;
    iat: number;
    jti: string;
    username: string;
}

export function isClientTokenPayload(
    tokenPayload: InternalTokenPayload | ClientTokenPayload
): tokenPayload is ClientTokenPayload {
    return tokenPayload.hasOwnProperty('permission');
}

export function decodeAuthToken<TReturn = ClientTokenPayload | InternalTokenPayload>(token: string): TReturn {
    if (process.env.NODE_ENV === 'development' && token.includes('mock')) {
        return (token === 'Bearer mockClientToken' ? getMockedClientPayload() : getMockedCustomerPayload()) as TReturn;
    }

    return decodeJWT(token.split(' ')[1]);
}

/* eslint-disable camelcase */
const mockPayloadBase: Omit<ClientTokenPayload, 'permission'> = {
    sub: '1',
    user_id: '',
    client_id: '1',
    email: 'email@email.com',
    exp: 9999999999,
    iss: '',
    aud: '',
    given_name: 'First',
    family_name: 'Last'
};

function getMockedPermissions(permissionsToExclude: PermissionType[]) {
    return Object.values(PermissionType).filter(
        permission => !permissionsToExclude.includes(permission)
    );
}

function getMockedClientPayload(): ClientTokenPayload {
    return {
        ...mockPayloadBase,
        permission: getMockedPermissions([ PermissionType.EDIT_WIRE_INFO ])
    };
}

function getMockedCustomerPayload(): ClientTokenPayload {
    return {
        ...mockPayloadBase,
        permission: getMockedPermissions([ PermissionType.VIEW_ROLES, PermissionType.CREATE_USERS ]),
        customer_id: '1'
    };
}
/* eslint-enable camelcase */

export function getAccountBaseUrl(clientId: string, customerId?: string) {
    return `/client/${clientId}${customerId ? `/customer/${customerId}` : ''}`;
}

export function getLoanBaseUrl(clientId: string, loanId: string, customerId?: string): string {
    return `${getAccountBaseUrl(clientId, customerId)}/loan/${loanId}`;
}
