/* eslint-disable max-len */
import { apiUtils } from '../api-utils';

import {
    getIncrementedUnderwritingStepId, setUnderwritingStepConfigs, underwritingStepConfigs
} from './underwriting-mock-data';
import { UnderwritingCategoryConfig, UnderwritingStepConfig, UnderwritingStepData } from './underwriting-types';


export function updateUnderwritingStep(
    clientId: string, underwritingCategoryId: string, underwritingStep: UnderwritingStepConfig
): Promise<UnderwritingStepConfig> {
    return apiUtils.put(`/client/${clientId}/underwriting-category/${underwritingCategoryId}/underwriting-step/${underwritingStep.id}`, underwritingStep);
}

export function getUnderwritingStepConfigs(
    clientId: string,
    underwritingCategoryConfigId: string
): Promise<UnderwritingStepConfig[]> {
    return apiUtils.get(`/client/${clientId}/underwriting-category/${underwritingCategoryConfigId}/step-config`);
    // setUnderwritingCategories(localStorage.getItem('underwritingCategories') ? JSON.parse(localStorage.getItem('underwritingCategories')!) : underwritingCategories);
    // const categoryConfig = underwritingCategories.find(c => c.id === underwritingCategoryConfigId);

    // if (!categoryConfig) {
    //     throw new Error('This category does not exist');
    // }

    // return new Promise((resolve) => {
    //     resolve(
    //         getUnderwritingStepsMockHandler(categoryConfig)
    //     );
    // });
}

export function createUnderwritingStepConfig(
    clientId: string,
    underwritingCategoryConfigId: string,
    stepName: string
): Promise<UnderwritingStepConfig> {
    return apiUtils.post(`/client/${clientId}/underwriting-category/${underwritingCategoryConfigId}/step-config`, { name: stepName });
    // const categoryConfig = underwritingCategories.find(c => c.id === underwritingCategoryConfigId);

    // if (!categoryConfig) {
    //     throw new Error('This category does not exist');
    // }

    // return new Promise((resolve) => {
    //     const createdConfig = createUnderwritingStepMockHandler(categoryConfig, stepName);
    //     localStorage.setItem('underwritingCategories', JSON.stringify(underwritingCategories)); // update localStorage
    //     resolve(createdConfig);
    // });
}

export function updateUnderwritingStepConfig(
    clientId: string,
    underwritingCategoryConfigId: string,
    updatedStepConfig: UnderwritingStepConfig
): Promise<UnderwritingStepConfig> {
    return apiUtils.put(`/client/${clientId}/underwriting-category/${underwritingCategoryConfigId}/step-config/${updatedStepConfig.id}`, updatedStepConfig);
    // const categoryConfig = underwritingCategories.find(c => c.id === underwritingCategoryConfigId);

    // if (!categoryConfig) {
    //     throw new Error('This category does not exist');
    // }

    // const oldStepConfig = underwritingStepConfigs.find(s => s.id === updatedStepConfig.id)!;

    // return new Promise((resolve) => {
    //     const updatedSteps = updateUnderwritingStepConfigMockHandler(categoryConfig, updatedStepConfig, oldStepConfig);
    //     localStorage.setItem('underwritingCategories', JSON.stringify(underwritingCategories)); // update localStorage
    //     resolve(updatedSteps);
    // });
}

export function deleteUnderwritingStepConfig(
    clientId: string, underwritingCategoryConfigId: string,
    underwritingStepConfig: UnderwritingStepConfig
): Promise<void> {
    return apiUtils.put(`/client/${clientId}/underwriting-category/${underwritingCategoryConfigId}/step-config/${underwritingStepConfig.id}`, {
        ...underwritingStepConfig,
        isActive: false
    });
    // const categoryConfig = underwritingCategories.find(c => c.id === underwritingCategoryConfigId);

    // if (!categoryConfig) {
    //     throw new Error('This category does not exist');
    // }

    // return new Promise((resolve) => {
    //     deleteUnderwritingStepConfigMockHandler(categoryConfig, underwritingStepConfigId);
    //     localStorage.setItem('underwritingCategories', JSON.stringify(underwritingCategories)); // update localStorage
    //     resolve();
    // });
}

// const underwritingStepDataArray: UnderwritingStepData[] = [];
export function getUnderwritingStepData(
    clientId: string, loanId: string, underwritingCategoryId: string
): Promise<UnderwritingStepData[]> {
    return apiUtils.get(`/client/${clientId}/loan/${loanId}/underwriting-category/${underwritingCategoryId}/underwriting-step-data`);

    // get from localStorage. If item is not yet defined in localStorage, set it
    // const storedData = localStorage.getItem('underwritingStepData');
    // if (storedData) {
    //     underwritingStepDataArray = JSON.parse(storedData);
    //     return new Promise((resolve) => {
    //         resolve(underwritingStepDataArray);
    //     });
    // } else {
    //     localStorage.setItem('underwritingStepData', JSON.stringify(underwritingStepDataArray));
    //     return new Promise((resolve) => {
    //         resolve(underwritingStepDataArray);
    //     });
    // }
}

export function updateUnderwritingStepData(
    clientId: string, loanId: string, underwritingCategoryId: string, underwritingStepId: string, underwritingStepData: UnderwritingStepData
): Promise<UnderwritingStepData> {
    return apiUtils.put(`/client/${clientId}/loan/${loanId}/underwriting-category/${underwritingCategoryId}/underwriting-step-data/${underwritingStepId}`, underwritingStepData);
    // look for an existing record in the array
    // if found, replace it
    // otherwise add one
    // const existingIndex = underwritingStepDataArray.findIndex(data => data.underwritingStepId === underwritingStepId);
    // if (existingIndex > -1) {
    //     underwritingStepDataArray[existingIndex] = underwritingStepData;
    // } else {
    //     underwritingStepDataArray.push(underwritingStepData);
    // }

    // // write to local storage
    // localStorage.setItem('underwritingStepData', JSON.stringify(underwritingStepDataArray));

    // return new Promise((resolve) => {
    //     resolve(underwritingStepData);
    // });
}

// Mock helpers

export function getUnderwritingStepsMockHandler(underWritingCategoryConfig: UnderwritingCategoryConfig): UnderwritingStepConfig[] {
    const underwritingStepsToReturn = [ ...underwritingStepConfigs ].filter(stepConfig => stepConfig.underwritingCategoryId === underWritingCategoryConfig.id).sort((a, b) => a.displayOrder - b.displayOrder);

    return underwritingStepsToReturn;
}

export function createUnderwritingStepMockHandler(
    underWritingCategoryConfig: UnderwritingCategoryConfig, name: string
): UnderwritingStepConfig {
    const newStepConfig: UnderwritingStepConfig = {
        id: getIncrementedUnderwritingStepId(),
        underwritingCategoryId: underWritingCategoryConfig.id,
        name,
        questionCount: 0,
        displayOrder: getUnderwritingStepsMockHandler(underWritingCategoryConfig).length + 1
    };

    underwritingStepConfigs.push(newStepConfig);

    return newStepConfig;
}

export function updateUnderwritingStepConfigMockHandler(
    underWritingCategoryConfig: UnderwritingCategoryConfig,
    updatedStepConfig: UnderwritingStepConfig,
    oldStepConfig: UnderwritingStepConfig
): UnderwritingStepConfig {
    if (oldStepConfig.displayOrder !== updatedStepConfig.displayOrder) {
        // Find the step at the target displayOrder
        const targetIndex = underwritingStepConfigs.findIndex(
            config => config.underwritingCategoryId === updatedStepConfig.underwritingCategoryId
             && config.displayOrder === updatedStepConfig.displayOrder
        );

        underwritingStepConfigs[targetIndex] = {
            ...underwritingStepConfigs[targetIndex],
            displayOrder: oldStepConfig.displayOrder
        };
    }

    const currentIndex = underwritingStepConfigs.findIndex(
        config => config.underwritingCategoryId === updatedStepConfig.underwritingCategoryId && config.id === updatedStepConfig.id
    );
    underwritingStepConfigs[currentIndex] = updatedStepConfig;

    return updatedStepConfig;
}

export function deleteUnderwritingStepConfigMockHandler(underWritingCategoryConfig: UnderwritingCategoryConfig, underwritingStepConfigId: string) {
    const stepIndex = underwritingStepConfigs.findIndex(s => s.underwritingCategoryId === underWritingCategoryConfig.id && s.id === underwritingStepConfigId);
    const deletedStep = underwritingStepConfigs[stepIndex];
    underwritingStepConfigs.splice(stepIndex, 1);

    // Reorder remaining stepConfigs
    setUnderwritingStepConfigs(underwritingStepConfigs.map(step => ({
        ...step,
        displayOrder: step.displayOrder > deletedStep.displayOrder
            ? step.displayOrder - 1
            : step.displayOrder
    })));
}
