import {
    Permission,
    PermissionCategory,
    PermissionLevel,
    PermissionType,
    Role,
    RolePermissionLevel,
    UserType
} from '@api';
import { getRandomEnumValue, randomBoolean, randomNum } from '@tsp-ui/core/utils';
import { rest } from 'msw';

import { getMockUrl } from '../../mocks/getMockUrl';


export const mocks = [
    rest.get(getMockUrl('/role'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(roles)
    ))),
    rest.get(getMockUrl('/client/:clientID/role'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(roles)
    ))),
    rest.get(getMockUrl('/customer/:customerID/role'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(roles)
    ))),
    rest.get(getMockUrl('/role/:roleID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(roles[parseInt(req.params.roleID.toString()) - 1])
    ))),
    rest.get(getMockUrl('/client/:clientID/role/:roleID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(roles[parseInt(req.params.roleID.toString()) - 1])
    ))),
    rest.get(getMockUrl('/customer/:customerID/role/:roleID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(roles[parseInt(req.params.roleID.toString()) - 1])
    ))),
    rest.put(getMockUrl('/role/:roleID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.put(getMockUrl('/client/:clientID/role/:roleID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.put(getMockUrl('/customer/:customerID/role/:roleID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.delete(getMockUrl('/role/:roleID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(500)
    ))),
    rest.delete(getMockUrl('/client/:clientID/role/:roleID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(500)
    ))),
    rest.delete(getMockUrl('/customer/:customerID/role/:roleID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(500)
    ))),
    rest.post(getMockUrl('/role'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(500),
        ctx.json({
            ...req.body as Role,
            id: `${roleID++}`
        })
    ))),
    rest.post(getMockUrl('/client/:clientID/role'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(500),
        ctx.json({
            ...req.body as Role,
            id: `${roleID++}`
        })
    ))),
    rest.post(getMockUrl('/customer/:customerID/role'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(500),
        ctx.json({
            ...req.body as Role,
            id: `${roleID++}`
        })
    ))),
    rest.get(getMockUrl('/auth/permission'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(permissions)
    ))),
    rest.get(getMockUrl('/role/:roleID/permission'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(getPermissionLevels(req.params.roleID as string))
    ))),
    rest.get(getMockUrl('/client/:clientID/role/:roleID/permission'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(getPermissionLevels(req.params.roleID as string))
    ))),
    rest.get(getMockUrl('/customer/:customerID/role/:roleID/permission'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(getPermissionLevels(req.params.roleID as string))
    ))),
    rest.put(getMockUrl('/role/:roleID/permission'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.put(getMockUrl('/client/:clientID/role/:roleID/permission'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.put(getMockUrl('/customer/:customerID/role/:roleID/permission'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    )))
];

let roleID = 1;
export const roles: Role[] = [
    {
        id: `${roleID++}`,
        type: UserType.INTERNAL,
        name: 'Loan setup',
        description: 'role description',
        isInUse: true
    },
    {
        id: `${roleID++}`,
        type: UserType.INTERNAL,
        name: 'Underwriter',
        description: 'role description',
        isInUse: true
    },
    {
        id: `${roleID++}`,
        type: UserType.INTERNAL,
        name: 'Compliance',
        description: 'role description',
        isInUse: false
    },
    {
        id: `${roleID++}`,
        type: UserType.CUSTOMER,
        name: 'Closing',
        description: 'role description',
        isInUse: false
    },
    {
        id: `${roleID++}`,
        type: UserType.CUSTOMER,
        name: 'Admin',
        description: 'role description',
        isInUse: false
    },
    {
        id: `${roleID++}`,
        type: UserType.CLIENT,
        name: 'Client user',
        description: 'role description',
        isInUse: false
    },
    {
        id: `${roleID++}`,
        type: UserType.CLIENT,
        name: 'Client admin',
        description: 'role description',
        isInUse: false
    },
    {
        id: `${roleID++}`,
        type: UserType.CUSTOMER,
        name: 'Customer user',
        description: 'role description',
        isInUse: false
    },
    {
        id: `${roleID++}`,
        type: UserType.CUSTOMER,
        name: 'Customer admin',
        description: 'role description',
        isInUse: false
    },
    {
        id: `${roleID++}`,
        type: UserType.CUSTOMER,
        name: 'Customer role',
        description: 'role description',
        isInUse: true
    }
];

let permissionID = 0;
const getPermissions: () => Permission[] = () => [ ...Array(randomNum(8, 16)) ].map(() => {
    const permissionType = getRandomEnumValue(PermissionType);

    return {
        id: `${permissionID++}`,
        permissionType,
        category: permissionTypeCategory[permissionType],
        description: permissionType,
        allowAssigned: randomBoolean(),
        allowableRoleTypes: [
            UserType.CLIENT,
            UserType.CUSTOMER,
            UserType.INTERNAL
        ]
    };
});

const permissionTypeCategory: Record<PermissionType, PermissionCategory> = {
    [PermissionType.CREATE_LOANS]: PermissionCategory.LOANS,
    [PermissionType.MANAGE_LOANS]: PermissionCategory.LOANS,
    [PermissionType.CREATE_PREMPACK]: PermissionCategory.LOANS,
    [PermissionType.CREATE_LOAN_TEAM]: PermissionCategory.LOANS,
    [PermissionType.VIEW_LOANS]: PermissionCategory.LOANS,
    [PermissionType.VIEW_ACTIVITY_HISTORY]: PermissionCategory.LOANS,
    [PermissionType.VIEW_LOAN_DOCS]: PermissionCategory.LOANS,
    [PermissionType.MANAGE_LOAN_DOCS]: PermissionCategory.LOANS,
    [PermissionType.EDIT_LOAN_STATUS]: PermissionCategory.LOANS,
    [PermissionType.VIEW_LOAN_COMMENTS]: PermissionCategory.LOANS,
    [PermissionType.MANAGE_LOAN_COMMENTS]: PermissionCategory.LOANS,
    [PermissionType.VIEW_RPT_DASH]: PermissionCategory.LOANS,
    [PermissionType.VIEW_PRICING]: PermissionCategory.LOANS,
    [PermissionType.VIEW_RATE_SHEETS]: PermissionCategory.LOANS,
    [PermissionType.MANAGE_RATE_SHEETS]: PermissionCategory.LOANS,

    [PermissionType.VIEW_USERS]: PermissionCategory.USERS,
    [PermissionType.EDIT_USERS]: PermissionCategory.USERS,
    [PermissionType.CREATE_USERS]: PermissionCategory.USERS,
    [PermissionType.DELETE_USERS]: PermissionCategory.USERS,

    [PermissionType.VIEW_ROLES]: PermissionCategory.ROLES,
    [PermissionType.EDIT_ROLES]: PermissionCategory.ROLES,
    [PermissionType.CLONE_ROLES]: PermissionCategory.ROLES,
    [PermissionType.DELETE_ROLES]: PermissionCategory.ROLES,
    [PermissionType.CREATE_ROLES]: PermissionCategory.ROLES,
    [PermissionType.EDIT_ROLE_PERMISSION]: PermissionCategory.ROLES,
    [PermissionType.VIEW_NOTIFICATION_CONFIGS]: PermissionCategory.ROLES,
    [PermissionType.MANAGE_NOTIFICATION_CONFIGS]: PermissionCategory.ROLES,
    [PermissionType.VIEW_CLIENT_NOTIFICATION_CONFIGS]: PermissionCategory.ROLES,
    [PermissionType.MANAGE_CLIENT_NOTIFICATION_CONFIGS]: PermissionCategory.ROLES,

    [PermissionType.VIEW_CLIENTS]: PermissionCategory.CLIENTS,
    [PermissionType.EDIT_CLIENTS]: PermissionCategory.CLIENTS,
    [PermissionType.EDIT_CLIENT_LOGO]: PermissionCategory.CLIENTS,
    [PermissionType.CREATE_CLIENTS]: PermissionCategory.CLIENTS,
    [PermissionType.OVERRIDE_CLIENT_INTERNAL_EDITS]: PermissionCategory.CLIENTS,

    [PermissionType.VIEW_DUE_DILIGENCE_CONFIG]: PermissionCategory.DUE_DILIGENCE_CONFIG,
    [PermissionType.EDIT_DUE_DILIGENCE_CONFIG]: PermissionCategory.DUE_DILIGENCE_CONFIG,

    [PermissionType.VIEW_INVESTORS]: PermissionCategory.INVESTORS,
    [PermissionType.MANAGE_INVESTORS]: PermissionCategory.INVESTORS,
    [PermissionType.VIEW_CLIENT_INVESTORS]: PermissionCategory.INVESTORS,
    [PermissionType.MANAGE_CLIENT_INVESTORS]: PermissionCategory.INVESTORS,
    [PermissionType.VIEW_LOAN_PROGRAMS]: PermissionCategory.INVESTORS,
    [PermissionType.MANAGE_LOAN_PROGRAMS]: PermissionCategory.INVESTORS,
    [PermissionType.VIEW_CLIENT_LOAN_PROGRAMS]: PermissionCategory.INVESTORS,
    [PermissionType.MANAGE_CLIENT_LOAN_PROGRAMS]: PermissionCategory.INVESTORS,
    [PermissionType.VIEW_ELIGIBILITY]: PermissionCategory.INVESTORS,
    [PermissionType.MANAGE_ELIGIBILITY]: PermissionCategory.INVESTORS,
    [PermissionType.VIEW_CLIENT_ELIGIBILITY]: PermissionCategory.INVESTORS,
    [PermissionType.MANAGE_CLIENT_ELIGIBILITY]: PermissionCategory.INVESTORS,
    [PermissionType.VIEW_EXCLUSION_OVERLAYS]: PermissionCategory.INVESTORS,
    [PermissionType.MANAGE_EXCLUSION_OVERLAYS]: PermissionCategory.INVESTORS,

    [PermissionType.VIEW_PRODUCTS]: PermissionCategory.PRODUCTS,
    [PermissionType.MANAGE_PRODUCTS]: PermissionCategory.PRODUCTS,
    [PermissionType.VIEW_PURCHASE_ADVICE_FEE]: PermissionCategory.PURCHASE_ADVICE,
    [PermissionType.MANAGE_PURCHASE_ADVICE_FEE]: PermissionCategory.PURCHASE_ADVICE,
    [PermissionType.VIEW_PURCHASE_ADVICE_CONFIG]: PermissionCategory.PURCHASE_ADVICE,
    [PermissionType.MANAGE_PURCHASE_ADVICE_CONFIG]: PermissionCategory.PURCHASE_ADVICE,
    [PermissionType.VIEW_LOCK_AVAILABILITY]: PermissionCategory.LOCK_AVAILABILITY,

    [PermissionType.VIEW_REFERENCE_GUIDES]: PermissionCategory.REFERENCE_GUIDES,
    [PermissionType.MANAGE_REFERENCE_GUIDES]: PermissionCategory.REFERENCE_GUIDES,

    [PermissionType.EDIT_LOCK_AVAILABILITY]: PermissionCategory.LOCK_AVAILABILITY,
    [PermissionType.VIEW_LOCK_AVAILABILITY_OVERRIDE]: PermissionCategory.LOCK_AVAILABILITY,
    [PermissionType.EDIT_LOCK_AVAILABILITY_OVERRIDE]: PermissionCategory.LOCK_AVAILABILITY,
    [PermissionType.CREATE_LOCK_AVAILABILITY_OVERRIDE]: PermissionCategory.LOCK_AVAILABILITY,
    [PermissionType.DELETE_LOCK_AVAILABILITY_OVERRIDE]: PermissionCategory.LOCK_AVAILABILITY,

    [PermissionType.VIEW_LOCK_SUSPENSIONS]: PermissionCategory.LOCK_SUSPENSIONS,
    [PermissionType.CREATE_LOCK_SUSPENSIONS]: PermissionCategory.LOCK_SUSPENSIONS,
    [PermissionType.DELETE_LOCK_SUSPENSIONS]: PermissionCategory.LOCK_SUSPENSIONS,

    [PermissionType.VIEW_LLPAS]: PermissionCategory.LLPAS,
    [PermissionType.MANAGE_LLPAS]: PermissionCategory.LLPAS,

    [PermissionType.VIEW_BULK_COMMITMENT]: PermissionCategory.BULK_COMMITMENTS,
    [PermissionType.MANAGE_BULK_COMMITMENT]: PermissionCategory.BULK_COMMITMENTS,

    [PermissionType.VIEW_CUSTOMERS]: PermissionCategory.CUSTOMERS,
    [PermissionType.EDIT_CUSTOMERS]: PermissionCategory.CUSTOMERS,
    [PermissionType.CREATE_CUSTOMERS]: PermissionCategory.CUSTOMERS,
    [PermissionType.VIEW_CUSTOMER_BRANCHES]: PermissionCategory.CUSTOMERS,
    [PermissionType.EDIT_CUSTOMER_BRANCHES]: PermissionCategory.CUSTOMERS,
    [PermissionType.CREATE_CUSTOMER_BRANCHES]: PermissionCategory.CUSTOMERS,
    [PermissionType.VIEW_CUSTOMER_CONTACTS]: PermissionCategory.CUSTOMERS,
    [PermissionType.MANAGE_CUSTOMER_CONTACTS]: PermissionCategory.CUSTOMERS,
    [PermissionType.VIEW_AGENCY_APPROVAL]: PermissionCategory.CUSTOMERS,
    [PermissionType.MANAGE_AGENCY_APPROVAL]: PermissionCategory.CUSTOMERS,
    [PermissionType.VIEW_CUSTOMER_REFERENCE_GUIDE]: PermissionCategory.CUSTOMERS,
    [PermissionType.MANAGE_ELIGIBLE_PRODUCTS]: PermissionCategory.CUSTOMERS,
    [PermissionType.VIEW_FEE_COMPENSATION]: PermissionCategory.CUSTOMERS,
    [PermissionType.MANAGE_FEE_COMPENSATION]: PermissionCategory.CUSTOMERS,
    [PermissionType.EDIT_WIRE_INFO]: PermissionCategory.CUSTOMERS,
    [PermissionType.VIEW_WIRE_INFO]: PermissionCategory.CUSTOMERS,
    [PermissionType.VIEW_INVESTOR_RELATIONSHIP]: PermissionCategory.CUSTOMERS,
    [PermissionType.MANAGE_INVESTOR_RELATIONSHIP]: PermissionCategory.CUSTOMERS,
    [PermissionType.VIEW_MI_COMPANY_RELATIONSHIP]: PermissionCategory.CUSTOMERS,
    [PermissionType.MANAGE_MI_COMPANY_RELATIONSHIP]: PermissionCategory.CUSTOMERS,
    [PermissionType.VIEW_WAREHOUSE_RELATIONSHIP]: PermissionCategory.CUSTOMERS,
    [PermissionType.MANAGE_WAREHOUSE_RELATIONSHIP]: PermissionCategory.CUSTOMERS,
    [PermissionType.VIEW_LICENSES]: PermissionCategory.CUSTOMERS,
    [PermissionType.MANAGE_LICENSES]: PermissionCategory.CUSTOMERS,
    [PermissionType.VIEW_DUE_DILIGENCE_STEP]: PermissionCategory.CUSTOMERS,
    [PermissionType.MANAGE_DUE_DILIGENCE_STEP]: PermissionCategory.CUSTOMERS,
    [PermissionType.VIEW_LOAN_ALLOCATION_LIMITS]: PermissionCategory.CUSTOMERS,
    [PermissionType.MANAGE_LOAN_ALLOCATION_LIMITS]: PermissionCategory.CUSTOMERS,
    [PermissionType.VIEW_CUSTOMER_ALERT_CONFIGS]: PermissionCategory.CUSTOMERS,
    [PermissionType.MANAGE_CUSTOMER_ALERT_CONFIGS]: PermissionCategory.CUSTOMERS,
    [PermissionType.VIEW_LOAN_STATUS_CONFIGS]: PermissionCategory.LOAN_STATUS_CONFIG,
    [PermissionType.MANAGE_LOAN_STATUS_CONFIGS]: PermissionCategory.LOAN_STATUS_CONFIG,
    [PermissionType.VIEW_LOAN_CONDITIONS]: PermissionCategory.CUSTOMERS,
    [PermissionType.MANAGE_LOAN_CONDITIONS]: PermissionCategory.CUSTOMERS,
    [PermissionType.VIEW_LOS_CONFIG]: PermissionCategory.CLIENTS,
    [PermissionType.MANAGE_LOS_CONFIG]: PermissionCategory.CLIENTS,
    [PermissionType.VIEW_CONDITION_CONFIGS]: PermissionCategory.CLIENTS,
    [PermissionType.MANAGE_CONDITION_CONFIGS]: PermissionCategory.CLIENTS,
    [PermissionType.MANAGE_LOAN_CONDITION_COMMENTS]: PermissionCategory.LOANS,
    [PermissionType.UPDATE_LOAN_CONDITION_ISRECEIVED]: PermissionCategory.LOANS,
    [PermissionType.VIEW_CONFIGURABLE_VALUES]: PermissionCategory.CLIENTS,
    [PermissionType.MANAGE_CONFIGURABLE_VALUES]: PermissionCategory.CLIENTS,
    [PermissionType.VIEW_FIELD_CONFIGS]: PermissionCategory.CLIENTS,
    [PermissionType.MANAGE_FIELD_CONFIGS]: PermissionCategory.CLIENTS,
    [PermissionType.VIEW_UNDERWRITING_CATEGORIES]: PermissionCategory.CLIENTS,
    [PermissionType.MANAGE_UNDERWRITING_CATEGORIES]: PermissionCategory.CLIENTS,
    [PermissionType.VIEW_ASSET_ACCOUNTS]: PermissionCategory.CLIENTS,
    [PermissionType.MANAGE_ASSET_ACCOUNTS]: PermissionCategory.CLIENTS
};

const permissions = getPermissions();

const getPermissionLevels: (roleId: string) => RolePermissionLevel[] = (roleId) => permissions.map(permission => ({
    roleId,
    permissionType: permission.permissionType,
    permissionLevel: getRandomEnumValue(PermissionLevel)
}));
