import {  AssetAccount, AssetAccountType, AssetSummary } from '@api';
import { rest } from 'msw';

import { getMockUrl } from '../../mocks/getMockUrl';


export const mocks = [
    rest.get(getMockUrl('/client/:clientId/loan/:loanId/asset-account'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(mockAssetAccounts)
    )),
    rest.get(getMockUrl('/client/:clientId/loan/:loanId/asset-account/summary'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(mockAssetSummary)
    )),
    rest.post(getMockUrl('/client/:clientId/loan/:loanId/asset-account'), async (req, res, ctx) => {
        const newAccount = await req.json() as AssetAccount;
        const createdAccount = {
            ...newAccount,
            id: String(mockAssetAccounts.length + 1)
        };

        return res(
            ctx.status(201),
            ctx.json(createdAccount)
        );
    }),
    rest.put(getMockUrl('/client/:clientId/loan/:loanId/asset-account/:id'), async (req, res, ctx) => {
        const { id } = req.params;
        const updatedAccount = await req.json() as AssetAccount;

        return res(
            ctx.status(200),
            ctx.json({
                ...updatedAccount,
                id
            })
        );
    }),
    rest.delete(getMockUrl('/client/:clientId/loan/:loanId/asset-account/:id'), (req, res, ctx) => res(
        ctx.status(204)
    ))
];

export const mockAssetAccounts: AssetAccount[] = [
    {
        id: '1',
        institutionName: 'Some institution',
        accountIdentifier: '2346345634',
        isReviewed: false,
        underwritingInstanceId: '123',
        type: '' as AssetAccountType,
        statementDate: '2021-12-01'
    },
    {
        id: '2',
        institutionName: 'Some institution',
        accountIdentifier: '2346345634',
        type: AssetAccountType.CHECKING_ACCOUNT,
        accountBalance: 10000,
        qualifyingBalance: 10000,
        statementDate: '2021-12-01',
        statementMonths: 2,
        isReviewed: true,
        underwritingInstanceId: '123'
    },
    {
        id: '3',
        institutionName: 'Some institution',
        accountIdentifier: '2346345634',
        type: AssetAccountType.SAVINGS_ACCOUNT,
        accountBalance: 10000,
        qualifyingBalance: 10000,
        statementDate: '2021-12-01',
        statementMonths: 2,
        isReviewed: true,
        underwritingInstanceId: '123'
    },
    {
        id: '4',
        institutionName: 'Some institution',
        accountIdentifier: '2346345634',
        type: AssetAccountType.STOCK,
        accountBalance: 10000,
        qualifyingBalance: 10000,
        statementDate: '2021-12-01',
        statementMonths: 2,
        isReviewed: true,
        underwritingInstanceId: '123'
    }
];

export const mockAssetSummary: AssetSummary = {
    underwritingInstanceId: '123',
    cashToClose: 40000,
    giftsReceivedAtClosing: 5000,
    requiredReserves: 10000,
    totalAssetsRequired: 45000,
    liquidAssets: 20000,
    giftsNotYetDeposited: 10000,
    netEquityFromSale: 0,
    liquidAssetsAvailable: 30000,
    otherAssetsAvailable: 100000
};
