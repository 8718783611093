import api, { PermissionType, UnderwritingCategoryConfig } from '@api';
import {
    ArrowDownward, ArrowUpward, Edit, RemoveCircleOutline
} from '@mui/icons-material';
import { Paper, Typography } from '@mui/material';
import {
    IconButton, SubtleLink, useConfirm, usePageMessage
} from '@tsp-ui/core';
import {
    findAdjacentActiveDisplayOrder, getMaxActiveDisplayOrder, getMinActiveDisplayOrder,
    useGetCurrentAccount, useHasPermission
} from '@utils';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { UnderwritingCategoryContext } from './UnderwritingCategoriesPage';
import styles from './UnderwritingCategoryConfigCard.module.scss';


interface UnderwritingCategoryConfigCardProps {
    underwritingCategoryConfig: UnderwritingCategoryConfig;
}

export function UnderwritingCategoryConfigCard({ underwritingCategoryConfig }: UnderwritingCategoryConfigCardProps) {
    const { underwritingCategoryConfigs, setUnderwritingCategoryConfigs } = useContext(UnderwritingCategoryContext);
    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_UNDERWRITING_CATEGORIES ]);
    const pageMessage = usePageMessage();
    const confirm = useConfirm();
    const { id: clientId } = useGetCurrentAccount();

    const [ moveUpLoading, setMoveUpLoading ] = useState(false);
    const [ moveDownLoading, setMoveDownLoading ] = useState(false);
    const [ isDeleting, setIsDeleting ] = useState(false);

    const activeConfigs = underwritingCategoryConfigs.filter(config => config.isActive !== false);

    async function moveConfig(direction: 'up' | 'down') {
        const setLoading = direction === 'up' ? setMoveUpLoading : setMoveDownLoading;
        setLoading(true);

        try {
            const targetDisplayOrder = findAdjacentActiveDisplayOrder(
                underwritingCategoryConfigs,
                underwritingCategoryConfig.displayOrder,
                direction
            );

            if (targetDisplayOrder === null) {
                throw new Error(`Cannot move ${direction}, no active config found in that direction`);
            }

            // Update the current config's display order
            await api.underwriting.updateUnderwritingCategoryConfig(clientId, {
                ...underwritingCategoryConfig,
                displayOrder: targetDisplayOrder
            });

            // Find the config that we're swapping with
            const swapConfig = activeConfigs.find(c => c.displayOrder === targetDisplayOrder);

            // Update the display order of the config we're swapping with
            if (swapConfig) {
                await api.underwriting.updateUnderwritingCategoryConfig(clientId, {
                    ...swapConfig,
                    displayOrder: underwritingCategoryConfig.displayOrder
                });
            }

            // Refresh the configs list
            setUnderwritingCategoryConfigs(await api.underwriting.getUnderwritingCategoryConfigs(clientId));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while moving the underwriting category configuration', error);
        }

        setLoading(false);
    }

    async function onDelete() {
        if (!await confirm('Are you sure you want to delete this underwriting category configuration? All associated steps and questions will be deleted.')) {
            return;
        }

        setIsDeleting(true);

        try {
            await api.underwriting.deleteUnderwritingCategoryConfig(clientId, underwritingCategoryConfig);
            setUnderwritingCategoryConfigs(await api.underwriting.getUnderwritingCategoryConfigs(clientId));

            pageMessage.success('Underwriting category configuration deleted');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while deleting the underwriting category configuration', error);
        }

        setIsDeleting(false);
    }

    // Get min and max display orders of active configs only
    const minActiveDisplayOrder = getMinActiveDisplayOrder(underwritingCategoryConfigs);
    const maxActiveDisplayOrder = getMaxActiveDisplayOrder(underwritingCategoryConfigs);

    return (
        <Paper
            className={styles.root}
            elevation={0}
        >
            <div>
                <SubtleLink
                    to={`detail/${underwritingCategoryConfig.id}`}
                >
                    {underwritingCategoryConfig.name}
                </SubtleLink>

                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    {underwritingCategoryConfig.steps.length > 0
                        ? `${underwritingCategoryConfig.steps.length} steps`
                        : 'No steps have been added to this configuration'}
                </Typography>
            </div>

            <span className={styles.icons}>
                <IconButton
                    loading={moveUpLoading}
                    tooltip={!canManage ? 'You don\'t have permission to move configs' : 'Move up'}
                    disabled={
                        underwritingCategoryConfig.displayOrder === minActiveDisplayOrder
                        || !underwritingCategoryConfig.displayOrder
                        || !canManage
                    }
                    onClick={() => moveConfig('up')}
                >
                    <ArrowUpward color="secondary" />
                </IconButton>

                <IconButton
                    loading={moveDownLoading}
                    tooltip={!canManage
                        ? 'You don\'t have permission to move configs' : 'Move down'}
                    disabled={
                        underwritingCategoryConfig.displayOrder === maxActiveDisplayOrder
                        || underwritingCategoryConfig.displayOrder === null
                        || !canManage
                    }
                    onClick={() => moveConfig('down')}
                >
                    <ArrowDownward color="secondary" />
                </IconButton>

                <IconButton
                    tooltip={canManage ? 'Edit underwriting category configuration' : 'You do not have permission to edit underwriting category configurations'}
                    disabled={!canManage}
                    component={Link}
                    to={`${underwritingCategoryConfig.id}`}
                >
                    <Edit color="secondary" />
                </IconButton>

                <IconButton
                    tooltip={canManage ? 'Delete underwriting category configuration' : 'You do not have permission to delete underwriting category configurations'}
                    disabled={!canManage}
                    onClick={onDelete}
                    loading={isDeleting}
                    autoMarginLoader={false}
                >
                    <RemoveCircleOutline color="error" />
                </IconButton>
            </span>
        </Paper>
    );
}
