import api, {
    PermissionType, UnderwritingCategoryConfig,
    UnderwritingStepConfig
} from '@api';
import {
    Button, FilledSection, RoutedDialogManager, useAsyncEffect, usePageMessage
} from '@tsp-ui/core';
import {
    useGetCurrentAccount, useHandlePromiseSettledResult, useHasPermission, withAuth
} from '@utils';
import Page from '@views/components/Page';
import {
    Dispatch, SetStateAction, createContext, useCallback, useMemo, useState
} from 'react';
import { Link, useParams } from 'react-router-dom';

import AddEditStepDialog from './AddEditStepDialog';
import styles from './UnderwritingCategoryDetailPage.module.scss';
import { UnderwritingStepConfigCard } from './UnderwritingStepConfigCard';


export interface UnderwritingStepContextValue {
    stepConfigs: UnderwritingStepConfig[];
    setStepConfigs: Dispatch<SetStateAction<UnderwritingStepConfig[]>>;
}

export const UnderwritingStepContext = createContext<UnderwritingStepContextValue>({
    stepConfigs: [],
    setStepConfigs: () => {}
});

export default function UnderwritingCategoryDetailPage() {
    const { underwritingCategoryConfigId  } = useParams();
    const handlePromiseSettledResult = useHandlePromiseSettledResult();
    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_UNDERWRITING_CATEGORIES ]);
    const pageMessage = usePageMessage();
    const { id: clientId } = useGetCurrentAccount();

    const [ loading, setLoading ] = useState(false);
    const [ categoryConfigs, setCategoryConfigs ] = useState<UnderwritingCategoryConfig[]>([]);
    const [ stepConfigs, setStepConfigs ] = useState<UnderwritingStepConfig[]>([]);

    useAsyncEffect(useCallback(async () => {
        if (!underwritingCategoryConfigId) {
            return;
        }

        setLoading(true);
        const [ categoryConfigsResult, stepConfigsResult ] = await Promise.allSettled([
            api.underwriting.getUnderwritingCategoryConfigs(clientId),
            api.underwriting.getUnderwritingStepConfigs(clientId, underwritingCategoryConfigId)
        ]);

        handlePromiseSettledResult(categoryConfigsResult, setCategoryConfigs, 'An error occurred while fetching underwriting category configs');
        handlePromiseSettledResult(stepConfigsResult, setStepConfigs, 'An error occurred while fetching underwriting step configs');

        setLoading(false);
    }, [
        clientId, handlePromiseSettledResult, underwritingCategoryConfigId
    ]));

    // Only reload steps when needed
    const reloadSteps = useCallback(async () => {
        if (!underwritingCategoryConfigId) {
            return;
        }

        try {
            const stepConfigs = await api.underwriting.getUnderwritingStepConfigs(
                clientId,
                underwritingCategoryConfigId
            );
            setStepConfigs(stepConfigs.sort((a, b) => a.displayOrder - b.displayOrder));
        } catch (e) {
            pageMessage.handleApiError('Error updating steps', e);
        }
    }, [
        clientId, underwritingCategoryConfigId, pageMessage
    ]);

    const underwritingStepContextValue = useMemo(() => ({
        stepConfigs,
        setStepConfigs
    }), [ stepConfigs ]);

    const currentConfig = categoryConfigs.find(c => c.id === underwritingCategoryConfigId);
    const orderdSteps = stepConfigs
        .filter(stepConfig => stepConfig.isActive)
        .sort((a, b) => a.displayOrder - b.displayOrder);

    return (
        <UnderwritingStepContext.Provider value={underwritingStepContextValue}>
            <Page
                header="Underwriting Category Details"
                loading={loading}
                breadcrumbs={[
                    'Underwriting Categories',
                    currentConfig?.name || ''
                ]}
            >
                <div className={styles.root}>
                    <FilledSection
                        className={styles.section}
                        header={(
                            <>
                                <span>
                                    Underwriting steps
                                </span>

                                <Button
                                    component={Link}
                                    to="add-step"
                                    disabled={!canManage}
                                    className={styles.headerButton}
                                >
                                    Add step
                                </Button>
                            </>
                        )}
                        noResultsMessage="No underwriting step configurations are currently configured."
                    >
                        {orderdSteps?.map((step) => (
                            <UnderwritingStepConfigCard
                                key={step.id}
                                underwritingStepConfig={step}
                                categoryConfig={currentConfig!}
                                onUpdate={reloadSteps}
                            />
                        ))}
                    </FilledSection>
                </div>

                <RoutedDialogManager
                    routes={dialogRoutes}
                />
            </Page>
        </UnderwritingStepContext.Provider>
    );
}

const dialogRoutes = {
    'add-step': withAuth(AddEditStepDialog, [ PermissionType.MANAGE_UNDERWRITING_CATEGORIES ], true),
    ':underwritingStepConfigId': withAuth(AddEditStepDialog, [ PermissionType.MANAGE_UNDERWRITING_CATEGORIES ], true)
};
