import { apiUtils } from '../api-utils';

// import { underwritingCategories } from './underwriting-mock-data';
import { UnderwritingCategoryConfig } from './underwriting-types';


export function getUnderwritingCategoryConfigs(clientId: string): Promise<UnderwritingCategoryConfig[]> {
    return apiUtils.get(`/client/${clientId}/underwriting-category`);
    // return new Promise((resolve) => {
    //     resolve(underwritingCategories);
    // });
}

export function createUnderwritingCategoryConfig(clientId: string, underwritingCategoryConfigToCreate: Omit<UnderwritingCategoryConfig, 'id'>): Promise<UnderwritingCategoryConfig> {
    return apiUtils.post(`/client/${clientId}/underwriting-category`, underwritingCategoryConfigToCreate);
    // function generateUniqueId(): string { // temp for mocking uw categories
    //     return `${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
    // }
    // const newUnderwritingCategoryConfig = {
    //     ...underwritingCategoryConfigToCreate,
    //     id: generateUniqueId()
    // };
    // underwritingCategories.push(newUnderwritingCategoryConfig);

    // return new Promise((resolve) => {
    //     resolve(newUnderwritingCategoryConfig);
    // });
}

export function updateUnderwritingCategoryConfig(
    clientId: string, underwritingCategoryConfigToUpdate: UnderwritingCategoryConfig
): Promise<UnderwritingCategoryConfig> {
    // eslint-disable-next-line max-len
    return apiUtils.put(`/client/${clientId}/underwriting-category/${underwritingCategoryConfigToUpdate.id}`, underwritingCategoryConfigToUpdate);
    // const updatedUnderwritingCategoryConfig = underwritingCategories.find(
    //     c => c.id === underwritingCategoryConfigToUpdate.id
    // );
    // if (!updatedUnderwritingCategoryConfig) {
    //     throw new Error('This category does not exist');
    // }
    // Object.assign(updatedUnderwritingCategoryConfig, underwritingCategoryConfigToUpdate);
    // return new Promise((resolve) => {
    //     resolve(updatedUnderwritingCategoryConfig);
    // });
}

export function deleteUnderwritingCategoryConfig(
    clientId: string, underwritingCategoryConfig: UnderwritingCategoryConfig
): Promise<void> {
    return apiUtils.put(`/client/${clientId}/underwriting-category/${underwritingCategoryConfig.id}`, {
        ...underwritingCategoryConfig,
        isActive: false
    });
    // const categoryIndex = underwritingCategories.findIndex(c => c.id === underwritingCategoryConfigId);
    // if (categoryIndex === -1) {
    //     throw new Error('This category does not exist');
    // }
    // underwritingCategories.splice(categoryIndex, 1);

    // return new Promise((resolve) => {
    //     resolve();
    // });
}
