/* eslint-disable max-len */
import api, {
    ConditionPriorTo, PermissionType, SpecialUnderwritingLiability, UnderwritingCategoryConfig,
    UnderwritingLiability, UnderwritingQuestionConfig, UnderwritingQuestionResponse,
    UnderwritingSideEffect, UnderwritingStepConfig, underwritingCategoryNameToConditionCategory
} from '@api';
import { CircularProgress, Typography } from '@mui/material';
import {
    Button, Loader, RoutedDialogManager, replaceItemByKey, useAsyncEffect, usePageMessage
} from '@tsp-ui/core';
import { useGetCurrentAccount, useHandlePromiseSettledResult, withAuth } from '@utils';
import {
    Dispatch, SetStateAction, createContext, useCallback, useContext, useState
} from 'react';

import { LoanDetailContext } from '../LoanDetailPage';

import { UnderwritingStepContext } from './UnderwritingCategoryDetail';
import styles from './UnderwritingStepSection.module.scss';
import AddEditSpecialLiabilityDialog from './question-cards/CustomQuestionCard/components/AddEditSpecialLiabilityDialog';
import AddEditUnderwritingLiabilityDialog from './question-cards/CustomQuestionCard/components/AddEditUnderwritingLiabilityDialog';
import { getQuestionIdsToDisplayAndSideEffects, questionTypeToComponentMap } from './underwriting-common';


export interface UnderWritingStepContextValue {
    underwritingStep: UnderwritingStepConfig | undefined;
    questionConfigs: UnderwritingQuestionConfig[];
    setQuestionConfigs: Dispatch<SetStateAction<UnderwritingQuestionConfig[]>>;
    questionResponses: UnderwritingQuestionResponse[];
    setQuestionResponses: Dispatch<SetStateAction<UnderwritingQuestionResponse[]>>;
    questionIdsToDisplay: string[];
    setQuestionIdsToDisplay: Dispatch<SetStateAction<string[]>>;
    setReadyToSubmit: Dispatch<SetStateAction<boolean>>;
    setSideEffects: Dispatch<SetStateAction<UnderwritingSideEffect[]>>;
    liabilities: UnderwritingLiability[];
    setLiabilities: Dispatch<SetStateAction<UnderwritingLiability[]>>;
    specialLiabilities: SpecialUnderwritingLiability[];
    setSpecialLiabilities: Dispatch<SetStateAction<SpecialUnderwritingLiability[]>>;
    /**
     * Updates the underwriting question with the new value.
     * Handles determining whether the update should be done to a main question or a nested question in a group.
     * Also updates questionIdsToDisplay based on the updated question.
     *
     * updateQuestionsToDisplay defaults to true
     */
    updateQuestionIdsToDisplayAndSideEffects: (questionResponses: UnderwritingQuestionResponse[], updateQuestionsToDisplay?: boolean) => void;

    // manages setting question response state
    // if there's an existing response it will replace it in the master state
    // if there is not an existing response it will add it to the master state
    setQuestionResponse: (response: UnderwritingQuestionResponse) => UnderwritingQuestionResponse[];
}

export const UnderWritingStepContext = createContext<UnderWritingStepContextValue>({
    underwritingStep: undefined,
    questionConfigs: [],
    setQuestionConfigs: () => {},
    questionResponses: [],
    setQuestionResponses: () => {},
    questionIdsToDisplay: [],
    setQuestionIdsToDisplay: () => {},
    setReadyToSubmit: () => {},
    updateQuestionIdsToDisplayAndSideEffects: () => {},
    setSideEffects: () => {},
    liabilities: [],
    setLiabilities: () => {},
    specialLiabilities: [],
    setSpecialLiabilities: () => {},
    setQuestionResponse: () => []
});

interface UnderwritingStepSectionProps {
    underwritingStepId: string;
    setUnderwritingStepId: Dispatch<SetStateAction<string | undefined>>;
    underwritingCategory: UnderwritingCategoryConfig;
}

export function UnderwritingStepSection({
    underwritingStepId,
    setUnderwritingStepId,
    underwritingCategory
}: UnderwritingStepSectionProps) {
    const { setConditions, loanDetail } = useContext(LoanDetailContext);
    const loanId = loanDetail?.id!;
    const losLoanId = loanDetail?.losLoanId;
    const pageMessage = usePageMessage();
    const { id: clientId, customerId } = useGetCurrentAccount();

    const [ loading, setLoading ] = useState(true);
    const [ submitLoading, setSubmitLoading ] = useState(false);
    const [ questionConfigs, setQuestionConfigs ] = useState<UnderwritingQuestionConfig[]>([]);
    const [ questionResponses, setQuestionResponses ] = useState<UnderwritingQuestionResponse[]>([]);
    const [ questionIdsToDisplay, setQuestionIdsToDisplay ] = useState<string[]>([]);
    const [ readyToSubmit, setReadyToSubmit ] = useState(false);
    const [ sideEffects, setSideEffects ] = useState<UnderwritingSideEffect[]>([]);
    const handlePromiseSettledResult = useHandlePromiseSettledResult();
    const [ liabilities, setLiabilities ] = useState<UnderwritingLiability[]>([]);
    const [ specialLiabilities, setSpecialLiabilities ] = useState<SpecialUnderwritingLiability[]>([]);

    const { setUnderwritingStepData, underwritingStepData, underwritingStepConfigs } = useContext(UnderwritingStepContext);

    const underwritingStep = underwritingStepConfigs.find(step => step.id === underwritingStepId);

    const isSubmitted = underwritingStepData.find(stepData => stepData.underwritingStepId === underwritingStepId)?.isSubmitted;

    const setQuestionResponse = useCallback((updatedResponse: UnderwritingQuestionResponse): UnderwritingQuestionResponse[] => {
        let updatedResponseList: UnderwritingQuestionResponse[] = [];

        const existingResponse = questionResponses.find(
            response => {
                const responseEntityId = response.entityId || response.frontEndEntityId;

                return response.questionId === updatedResponse.questionId && (responseEntityId === updatedResponse.entityId || responseEntityId === updatedResponse.frontEndEntityId);
            }
        );

        const associatedQuestionConfig = questionConfigs.find(q => q.id === updatedResponse.questionId)!;

        if ((updatedResponse.entityId || updatedResponse.frontEndEntityId) && associatedQuestionConfig.parentQuestionId) {
            // if this is a foreach subquestion, we need to replace by the id and by the entityId, because there could be multiple responses for the same question Id
            if (existingResponse) {
                updatedResponseList = questionResponses.map(responseItem => {
                    const responseItemEntityId = responseItem.entityId || responseItem.frontEndEntityId;
                    if ((responseItemEntityId === updatedResponse.entityId || responseItemEntityId === updatedResponse.frontEndEntityId) && responseItem.questionId === updatedResponse.questionId) {
                        return updatedResponse;
                    }
                    return responseItem;
                });
            } else {
                updatedResponseList = [ ...questionResponses, updatedResponse ];
            }
        } else {
            // eslint-disable-next-line no-lonely-if
            if (existingResponse) {
                updatedResponseList = questionResponses.map(responseItem => {
                    const responseItemEntityId = responseItem.entityId || responseItem.frontEndEntityId;
                    if (responseItem.questionId === updatedResponse.questionId && (responseItemEntityId === updatedResponse.entityId || responseItemEntityId === updatedResponse.frontEndEntityId)) {
                        return updatedResponse;
                    }
                    return responseItem;
                });
            } else {
                updatedResponseList = [ ...questionResponses, updatedResponse ];
            }
        }

        setQuestionResponses(updatedResponseList);
        return updatedResponseList;
    }, [ questionConfigs, questionResponses ]);

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            const [ underwritingQuestionConfigsResult, underwritingQuestionResponsesResult ] = await Promise.allSettled([
                api.underwriting.getUnderwritingQuestionConfigs(clientId, underwritingCategory.id, underwritingStepId),
                api.underwriting.getUnderwritingQuestionResponses(clientId, loanId, underwritingStepId)
            ]);

            handlePromiseSettledResult(underwritingQuestionConfigsResult, setQuestionConfigs, 'An error occurred while fetching underwriting question configs');
            handlePromiseSettledResult(underwritingQuestionResponsesResult, setQuestionResponses, 'An error occurred while fetching underwriting question responses');

            if (underwritingQuestionConfigsResult.status === 'fulfilled' && underwritingQuestionResponsesResult.status === 'fulfilled') {
                const { questionIdsToDisplay, sideEffects } = getQuestionIdsToDisplayAndSideEffects(underwritingQuestionConfigsResult.value, underwritingQuestionResponsesResult.value);

                setQuestionIdsToDisplay(questionIdsToDisplay);
                setSideEffects(sideEffects);
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching underwriting step details', error);
        }

        setLoading(false);
    }, [
        clientId, underwritingCategory.id, underwritingStepId, loanId, handlePromiseSettledResult, pageMessage
    ]));

    useAsyncEffect(useCallback(async () => {
        if (!loanId) {
            return;
        }

        setLoading(true);

        try {
            const [ liabilities, specialLiabilities ] = await Promise.all([
                api.underwriting.liability.getUnderwritingLiabilities(clientId, loanId, customerId),
                api.underwriting.liability.getSpecialUnderwritingLiabilities(clientId, loanId, customerId)
            ]);

            setLiabilities(liabilities);
            setSpecialLiabilities(specialLiabilities);
        } catch (e) {
            pageMessage.handleApiError('An error occurred while fetching underwriting liabilities', e);
        }
        setLoading(false);
    }, [
        clientId, customerId, loanId, pageMessage
    ]));

    function updateQuestionIdsToDisplayAndSideEffects(
        questionResponses: UnderwritingQuestionResponse[], updateQuestionsToDisplay: boolean = true
    ) {
        const { questionIdsToDisplay, sideEffects } = getQuestionIdsToDisplayAndSideEffects(questionConfigs, questionResponses);
        updateQuestionsToDisplay && setQuestionIdsToDisplay(questionIdsToDisplay);
        setSideEffects(sideEffects);
    }

    async function onSubmit() {
        setSubmitLoading(true);

        try {
            const updatedStepData = await api.underwriting.updateUnderwritingStepData(
                clientId, loanId, underwritingCategory.id, underwritingStepId, {
                    underwritingStepId,
                    isSubmitted: true
                }
            );

            // TODO if we support updating existing responses in the future, we need to implement that here
            await api.underwriting.createUnderwritingQuestionResponses(
                clientId, loanId, underwritingStepId, questionResponses
            );

            await generateConditions();

            setUnderwritingStepData((underwritingStepData) => {
                // if step data entry already exists, replace it
                // otherwise add a new one
                const existingIndex = underwritingStepData.findIndex(data => data.underwritingStepId === underwritingStepId);
                if (existingIndex > -1) {
                    return replaceItemByKey(underwritingStepData, updatedStepData, 'underwritingStepId');
                } else {
                    return [ ...underwritingStepData, updatedStepData ];
                }
            });

            // go to next step
            setUnderwritingStepId((stepId) => {
                const currentStepIndex = underwritingStepConfigs.findIndex(step => step.id === stepId);
                const nextStepIndex = currentStepIndex === -1 ? -1 : currentStepIndex + 1;
                const nextStepId = underwritingStepConfigs[nextStepIndex]?.id || '';
                return nextStepId;
            });

            // when moving to next step, reset the ready to submit state to false
            setReadyToSubmit(false);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while submitting underwriting step', error);
        }

        setSubmitLoading(false);
    }

    async function generateConditions() {
        const conditionsToGenerate = sideEffects.filter(sideEffect => sideEffect.type === 'GENERATE_CONDITION');
        if (conditionsToGenerate.length) {
            try {
                const newConditions = await Promise.all(
                    conditionsToGenerate.map((sideEffect) => api.loans.createUnderwritingCondition(
                        clientId,
                            losLoanId!,
                            {
                                // underwritingStepId is not implemented on the backend so it doesn't do anything
                                underwritingStepId,
                                title: sideEffect.text || 'N/A',
                                description: sideEffect.description || 'N/A',
                                source: 'Automated Conditions',
                                category: underwritingCategoryNameToConditionCategory[
                                    underwritingCategory.name as keyof typeof underwritingCategoryNameToConditionCategory // eslint-disable-line max-len
                                ],
                                priorTo: ConditionPriorTo.APPROVAL,
                                requestedFrom: '',
                                daysToReceive: 12,
                                expectedDate: '',
                                allowToClear: false,
                                printExternally: false,
                                printInternally: false
                            }
                    ))
                );

                setConditions((conditions) => [
                    ...conditions,
                    ...newConditions
                ]);

                pageMessage.info(
                    `${newConditions.length === 1 ? 'A condition was' : 'Conditions were'} added to this loan`,
                    newConditions.map(condition => (
                        `${condition.title}: ${condition.description}`
                    ))
                );
            } catch (error) {
                pageMessage.handleApiError('An error occurred while generating conditions', error);
            }
        }
    }

    const numConditionsToGenerate = sideEffects.filter(se => se.type === 'GENERATE_CONDITION').length;

    const filteredQuestionConfigs = questionConfigs.filter(q => q.isActive);

    return loading ? <Loader loading /> : (
        <UnderWritingStepContext.Provider value={{
            underwritingStep,
            questionConfigs,
            setQuestionConfigs,
            questionResponses,
            setQuestionResponses,
            questionIdsToDisplay,
            setQuestionIdsToDisplay,
            setReadyToSubmit,
            updateQuestionIdsToDisplayAndSideEffects,
            setSideEffects,
            liabilities,
            setLiabilities,
            specialLiabilities,
            setSpecialLiabilities,
            setQuestionResponse
        }}
        >
            <div className={styles.underwritingStepSection}>
                <div className={styles.underwritingStepSectionTitle}>
                    <div />

                    <Typography variant="h6">
                        {underwritingStep?.name}
                    </Typography>
                </div>

                {filteredQuestionConfigs?.map(questionConfig => {
                    const questionResponse = questionResponses.find(response => response.questionId === questionConfig.id);
                    const UnderwritingQuestionCard = questionTypeToComponentMap[questionConfig.type];

                    return questionIdsToDisplay.includes(questionConfig.id)
                        ? (
                            <UnderwritingQuestionCard
                                key={questionConfig.id}
                                questionConfig={questionConfig}
                                questionResponse={questionResponse || {
                                    id: '',
                                    questionId: questionConfig.id
                                }}
                            />
                        )
                        : null;
                })}

                <div className={styles.submitButtonContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!readyToSubmit || isSubmitted || submitLoading}
                        onClick={onSubmit}
                        tooltip={isSubmitted ? 'This step has already been submitted' : ''}
                    >
                        Submit Step
                    </Button>

                    {submitLoading && (
                        <CircularProgress
                            size={22}
                            className={styles.loading}
                        />
                    )}
                </div>

                {!!numConditionsToGenerate && (
                    <Typography
                        variant="caption"
                        className={styles.sideEffects}
                    >
                        {!isSubmitted && `Submitting will generate ${numConditionsToGenerate} condition${numConditionsToGenerate > 1 ? 's' : ''}`}
                    </Typography>
                )}

                <RoutedDialogManager routes={dialogRoutes} />
            </div>
        </UnderWritingStepContext.Provider>
    );
}

const dialogRoutes = {
    'add-liability': withAuth(AddEditUnderwritingLiabilityDialog, [ PermissionType.MANAGE_UNDERWRITING_CATEGORIES ], true),
    'edit/:liabilityId': withAuth(AddEditUnderwritingLiabilityDialog, [ PermissionType.MANAGE_UNDERWRITING_CATEGORIES ], true),
    'add-special-liability': withAuth(AddEditSpecialLiabilityDialog, [ PermissionType.MANAGE_UNDERWRITING_CATEGORIES ], true),
    'edit-special-liability/:specialLiabilityId': withAuth(AddEditSpecialLiabilityDialog, [ PermissionType.MANAGE_UNDERWRITING_CATEGORIES ], true)
};
