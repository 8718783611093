export enum PermissionType {
    // Loans
    VIEW_LOANS = 'VIEW_LOANS',
    MANAGE_LOANS = 'MANAGE_LOANS',
    VIEW_ACTIVITY_HISTORY = 'VIEW_ACTIVITY_HISTORY',
    VIEW_LOAN_DOCS = 'VIEW_LOAN_DOCS',
    MANAGE_LOAN_DOCS = 'MANAGE_LOAN_DOCS',
    EDIT_LOAN_STATUS = 'EDIT_LOAN_STATUS',
    VIEW_LOAN_COMMENTS = 'VIEW_LOAN_COMMENTS',
    MANAGE_LOAN_COMMENTS = 'MANAGE_LOAN_COMMENTS',
    CREATE_LOANS = 'CREATE_LOANS',
    CREATE_PREMPACK = 'CREATE_PREMPACK',
    CREATE_LOAN_TEAM = 'CREATE_LOAN_TEAM',
    VIEW_RPT_DASH = 'VIEW_RPT_DASH',
    VIEW_PRICING = 'VIEW_PRICING',
    VIEW_RATE_SHEETS = 'VIEW_RATE_SHEETS',
    MANAGE_RATE_SHEETS = 'MANAGE_RATE_SHEETS',

    // Users
    VIEW_USERS = 'VIEW_USERS',
    EDIT_USERS = 'EDIT_USERS',
    CREATE_USERS = 'CREATE_USERS',
    DELETE_USERS = 'DELETE_USERS',

    // Roles
    VIEW_ROLES = 'VIEW_ROLES',
    EDIT_ROLES = 'EDIT_ROLES',
    DELETE_ROLES = 'DELETE_ROLES',
    CREATE_ROLES = 'CREATE_ROLES',
    CLONE_ROLES = 'CLONE_ROLES',
    EDIT_ROLE_PERMISSION = 'EDIT_ROLE_PERMISSION',

    // Notifications
    VIEW_NOTIFICATION_CONFIGS = 'VIEW_NOTIFICATION_CONFIGS',
    MANAGE_NOTIFICATION_CONFIGS = 'MANAGE_NOTIFICATION_CONFIGS',
    VIEW_CLIENT_NOTIFICATION_CONFIGS = 'VIEW_CLIENT_NOTIFICATION_CONFIGS',
    MANAGE_CLIENT_NOTIFICATION_CONFIGS = 'MANAGE_CLIENT_NOTIFICATION_CONFIGS',

    // Clients

    VIEW_CLIENTS = 'VIEW_CLIENTS',
    EDIT_CLIENTS = 'EDIT_CLIENTS',
    EDIT_CLIENT_LOGO = 'EDIT_CLIENT_LOGO',
    CREATE_CLIENTS = 'CREATE_CLIENTS',
    OVERRIDE_CLIENT_INTERNAL_EDITS = 'OVERRIDE_CLIENT_INTERNAL_EDITS',

    // Lock Availability
    VIEW_LOCK_AVAILABILITY = 'VIEW_LOCK_AVAILABILITY',
    EDIT_LOCK_AVAILABILITY = 'EDIT_LOCK_AVAILABILITY',
    VIEW_LOCK_AVAILABILITY_OVERRIDE = 'VIEW_LOCK_AVAILABILITY_OVERRIDE',
    EDIT_LOCK_AVAILABILITY_OVERRIDE = 'EDIT_LOCK_AVAILABILITY_OVERRIDE',
    CREATE_LOCK_AVAILABILITY_OVERRIDE = 'CREATE_LOCK_AVAILABILITY_OVERRIDE',
    DELETE_LOCK_AVAILABILITY_OVERRIDE = 'DELETE_LOCK_AVAILABILITY_OVERRIDE',

    // Lock Suspensions
    VIEW_LOCK_SUSPENSIONS = 'VIEW_LOCK_SUSPENSIONS',
    CREATE_LOCK_SUSPENSIONS = 'CREATE_LOCK_SUSPENSIONS',
    DELETE_LOCK_SUSPENSIONS = 'DELETE_LOCK_SUSPENSIONS',

    // Products
    VIEW_PRODUCTS = 'VIEW_PRODUCTS',
    MANAGE_PRODUCTS = 'MANAGE_PRODUCTS',

    // Purchase Advice
    VIEW_PURCHASE_ADVICE_CONFIG = 'VIEW_PURCHASE_ADVICE_CONFIG',
    MANAGE_PURCHASE_ADVICE_CONFIG = 'MANAGE_PURCHASE_ADVICE_CONFIG',
    VIEW_PURCHASE_ADVICE_FEE = 'VIEW_PURCHASE_ADVICE_FEE',
    MANAGE_PURCHASE_ADVICE_FEE = 'MANAGE_PURCHASE_ADVICE_FEE',

    // Investors
    VIEW_INVESTORS = 'VIEW_INVESTORS',
    MANAGE_INVESTORS = 'MANAGE_INVESTORS',
    VIEW_CLIENT_INVESTORS = 'VIEW_CLIENT_INVESTORS',
    MANAGE_CLIENT_INVESTORS = 'MANAGE_CLIENT_INVESTORS',

    // Loan Programs & Eligibility
    VIEW_LOAN_PROGRAMS = 'VIEW_LOAN_PROGRAMS',
    MANAGE_LOAN_PROGRAMS = 'MANAGE_LOAN_PROGRAMS',
    VIEW_CLIENT_LOAN_PROGRAMS = 'VIEW_CLIENT_LOAN_PROGRAMS',
    MANAGE_CLIENT_LOAN_PROGRAMS = 'MANAGE_CLIENT_LOAN_PROGRAMS',
    VIEW_ELIGIBILITY = 'VIEW_ELIGIBILITY',
    MANAGE_ELIGIBILITY = 'MANAGE_ELIGIBILITY',
    VIEW_CLIENT_ELIGIBILITY = 'VIEW_CLIENT_ELIGIBILITY',
    MANAGE_CLIENT_ELIGIBILITY = 'MANAGE_CLIENT_ELIGIBILITY',
    VIEW_EXCLUSION_OVERLAYS = 'VIEW_EXCLUSION_OVERLAYS',
    MANAGE_EXCLUSION_OVERLAYS = 'MANAGE_EXCLUSION_OVERLAYS',

    // LLPAs
    VIEW_LLPAS = 'VIEW_LLPAS',
    MANAGE_LLPAS = 'MANAGE_LLPAS',

    // Bulk Commitments
    VIEW_BULK_COMMITMENT = 'VIEW_BULK_COMMITMENT',
    MANAGE_BULK_COMMITMENT = 'MANAGE_BULK_COMMITMENT',

    // DueDiligenceConfig
    VIEW_DUE_DILIGENCE_CONFIG = 'VIEW_DUE_DILIGENCE_CONFIG',
    EDIT_DUE_DILIGENCE_CONFIG = 'EDIT_DUE_DILIGENCE_CONFIG',

    // Customers
    VIEW_CUSTOMERS = 'VIEW_CUSTOMERS',
    CREATE_CUSTOMERS = 'CREATE_CUSTOMERS',
    EDIT_CUSTOMERS = 'EDIT_CUSTOMERS',
    VIEW_CUSTOMER_REFERENCE_GUIDE = 'VIEW_CUSTOMER_REFERENCE_GUIDE',

    // Customer eligible products (view for this is under VIEW_CUSTOMERS)
    MANAGE_ELIGIBLE_PRODUCTS = 'MANAGE_ELIGIBLE_PRODUCTS',

    // Branches
    VIEW_CUSTOMER_BRANCHES = 'VIEW_CUSTOMER_BRANCHES',
    EDIT_CUSTOMER_BRANCHES = 'EDIT_CUSTOMER_BRANCHES',
    CREATE_CUSTOMER_BRANCHES = 'CREATE_CUSTOMER_BRANCHES',

    // Contacts
    VIEW_CUSTOMER_CONTACTS = 'VIEW_CUSTOMER_CONTACTS',
    MANAGE_CUSTOMER_CONTACTS = 'MANAGE_CUSTOMER_CONTACTS',

    // Licenses
    VIEW_LICENSES = 'VIEW_LICENSES',
    MANAGE_LICENSES = 'MANAGE_LICENSES',

    // MiCompanyRelationship
    VIEW_MI_COMPANY_RELATIONSHIP = 'VIEW_MI_COMPANY_RELATIONSHIP',
    MANAGE_MI_COMPANY_RELATIONSHIP = 'MANAGE_MI_COMPANY_RELATIONSHIP',

    // WarehouseRelationship
    VIEW_WAREHOUSE_RELATIONSHIP = 'VIEW_WAREHOUSE_RELATIONSHIP',
    MANAGE_WAREHOUSE_RELATIONSHIP = 'MANAGE_WAREHOUSE_RELATIONSHIP',

    // InvestorRelationship
    VIEW_INVESTOR_RELATIONSHIP = 'VIEW_INVESTOR_RELATIONSHIP',
    MANAGE_INVESTOR_RELATIONSHIP = 'MANAGE_INVESTOR_RELATIONSHIP',

    // Compensation and Fees
    VIEW_FEE_COMPENSATION = 'VIEW_FEE_COMPENSATION',
    MANAGE_FEE_COMPENSATION = 'MANAGE_FEE_COMPENSATION',

    // Wire Info
    VIEW_WIRE_INFO = 'VIEW_WIRE_INFO',
    EDIT_WIRE_INFO = 'EDIT_WIRE_INFO',

    // Reference Guides
    VIEW_REFERENCE_GUIDES = 'VIEW_REFERENCE_GUIDES',
    MANAGE_REFERENCE_GUIDES = 'MANAGE_REFERENCE_GUIDES', // Edit, create, and delete reference guide sections and documents for a client

    // Agency Approval
    VIEW_AGENCY_APPROVAL = 'VIEW_AGENCY_APPROVAL',
    MANAGE_AGENCY_APPROVAL = 'MANAGE_AGENCY_APPROVAL',

    // Due Diligence Step
    VIEW_DUE_DILIGENCE_STEP = 'VIEW_DUE_DILIGENCE_STEP',
    MANAGE_DUE_DILIGENCE_STEP = 'MANAGE_DUE_DILIGENCE_STEP',

    // Loan Allocation Limits
    VIEW_LOAN_ALLOCATION_LIMITS = 'VIEW_LOAN_ALLOCATION_LIMITS',
    MANAGE_LOAN_ALLOCATION_LIMITS = 'MANAGE_LOAN_ALLOCATION_LIMITS',

    // Customer Alert Configs
    VIEW_CUSTOMER_ALERT_CONFIGS = 'VIEW_CUSTOMER_ALERT_CONFIGS',
    MANAGE_CUSTOMER_ALERT_CONFIGS = 'MANAGE_CUSTOMER_ALERT_CONFIGS',

    // Loan Status Configs
    VIEW_LOAN_STATUS_CONFIGS = 'VIEW_LOAN_STATUS_CONFIGS',
    MANAGE_LOAN_STATUS_CONFIGS = 'MANAGE_LOAN_STATUS_CONFIGS',

    // Loan Conditions
    VIEW_LOAN_CONDITIONS = 'VIEW_LOAN_CONDITIONS',
    MANAGE_LOAN_CONDITIONS = 'MANAGE_LOAN_CONDITIONS',
    MANAGE_LOAN_CONDITION_COMMENTS = 'MANAGE_LOAN_CONDITION_COMMENTS',
    UPDATE_LOAN_CONDITION_ISRECEIVED = 'UPDATE_LOAN_CONDITION_ISRECEIVED', // permission for customers to mark conditions as received

    // Client LOS Configuration
    VIEW_LOS_CONFIG = 'VIEW_LOS_CONFIG',
    MANAGE_LOS_CONFIG = 'MANAGE_LOS_CONFIG',

    // Client Loan Condition Configuration
    VIEW_CONDITION_CONFIGS = 'VIEW_CONDITION_CONFIGS',
    MANAGE_CONDITION_CONFIGS = 'MANAGE_CONDITION_CONFIGS',

    // Client Loan Fields Configuration
    VIEW_FIELD_CONFIGS = 'VIEW_FIELD_CONFIGS',
    MANAGE_FIELD_CONFIGS = 'MANAGE_FIELD_CONFIGS',

    // Configurable Values
    VIEW_CONFIGURABLE_VALUES = 'VIEW_CONFIGURABLE_VALUES',
    MANAGE_CONFIGURABLE_VALUES = 'MANAGE_CONFIGURABLE_VALUES',

    // Underwriting
    VIEW_UNDERWRITING_CATEGORIES = 'VIEW_UNDERWRITING_CATEGORIES',
    MANAGE_UNDERWRITING_CATEGORIES = 'MANAGE_UNDERWRITING_CATEGORIES',

    // Underwriting
    VIEW_ASSET_ACCOUNTS = 'VIEW_ASSET_ACCOUNTS',
    MANAGE_ASSET_ACCOUNTS = 'MANAGE_ASSET_ACCOUNTS'
}
