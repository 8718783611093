/* eslint-disable max-len */

import { apiUtils } from '../api-utils';

// import { setQuestionConfigs, underwritingQuestionConfigs } from './underwriting-mock-data';
import { UnderwritingQuestionConfig } from './underwriting-types';


export async function getUnderwritingQuestionConfigs(
    clientId: string,
    underwritingCategoryConfigId: string,
    underwritingStepConfigId: string
): Promise<UnderwritingQuestionConfig[]> {
    const questionConfigs: UnderwritingQuestionConfig[] = await apiUtils.get(`/client/${clientId}/underwriting-category/${underwritingCategoryConfigId}/step-config/${underwritingStepConfigId}/question-config`);
    // we need to inject next.text, pending nextText being added to the backend
    // if next.action === 'CONTINUE' then next.text = 'Continue'
    // if next.action === 'END' then next.text = 'Done'
    questionConfigs.forEach(questionConfig => {
        if (questionConfig.next?.action === 'CONTINUE') {
            questionConfig.next.text = 'Continue';
        } else if (questionConfig.next?.action === 'END') {
            questionConfig.next.text = 'Done';
        }
    });

    const questionConfigsFlat: UnderwritingQuestionConfig[] = [];
    questionConfigs.forEach(questionConfig => {
        questionConfigsFlat.push(questionConfig);
        if (questionConfig.questions?.length) {
            questionConfig.questions.forEach(subQuestion => {
                questionConfigsFlat.push(subQuestion);
            });
        }
    }); // front-end is expecting a flat array of all the question while the backend was returning subquestions as a nested array. TODO we can handle this better post-demo


    return questionConfigsFlat;

    // setQuestionConfigs(localStorage.getItem('questionConfigs') ? JSON.parse(localStorage.getItem('questionConfigs')!) : underwritingQuestionConfigs);
    // const filteredConfigs = underwritingQuestionConfigs.filter(q => q.stepId === underwritingStepConfigId);
    // return new Promise(res => res(filteredConfigs));
}

export function createUnderwritingQuestionConfig(
    clientId: string,
    underwritingCategoryConfigId: string,
    underwritingStepConfigId: string,
    newQuestionConfig: UnderwritingQuestionConfig
): Promise<UnderwritingQuestionConfig> {
    return apiUtils.post(`/client/${clientId}/underwriting-category/${underwritingCategoryConfigId}/step-config/${underwritingStepConfigId}/question-config`, newQuestionConfig);
    // function generateUniqueId(): string { // temp for mocking uw questions
    //     return `${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
    // }
    // newQuestionConfig.id = generateUniqueId();
    // newQuestionConfig.stepId = underwritingStepConfigId;
    // setQuestionConfigs([ ...underwritingQuestionConfigs, newQuestionConfig ]);
    // localStorage.setItem('questionConfigs', JSON.stringify(underwritingQuestionConfigs)); // update localStorage
    // return new Promise(res => res(newQuestionConfig));
}

export function updateUnderwritingQuestionConfig(
    clientId: string,
    underwritingCategoryConfigId: string,
    underwritingStepConfigId: string,
    underwritingQuestionConfig: UnderwritingQuestionConfig
): Promise<UnderwritingQuestionConfig> {
    return apiUtils.put(`/client/${clientId}/underwriting-category/${underwritingCategoryConfigId}/step-config/${underwritingStepConfigId}/question-config/${underwritingQuestionConfig.id}`, underwritingQuestionConfig);
    // setQuestionConfigs(replaceItemById(underwritingQuestionConfigs, underwritingQuestionConfig));
    // localStorage.setItem('questionConfigs', JSON.stringify(underwritingQuestionConfigs)); // update localStorage
    // return new Promise(res => res(underwritingQuestionConfig));
}

export function deleteUnderwritingQuestionConfig(
    clientId: string,
    underwritingCategoryConfigId: string,
    underwritingStepConfigId: string,
    underwritingQuestionConfig: UnderwritingQuestionConfig
): Promise<void> {
    return apiUtils.put(`/client/${clientId}/underwriting-category/${underwritingCategoryConfigId}/step-config/${underwritingStepConfigId}/question-config/${underwritingQuestionConfig.id}`, {
        ...underwritingQuestionConfig,
        isActive: false
    });

    // setQuestionConfigs(underwritingQuestionConfigs.filter(q => q.id !== underwritingQuestionConfigId));
    // localStorage.setItem('questionConfigs', JSON.stringify(underwritingQuestionConfigs)); // update localStorage
    // return new Promise(res => res());
}
