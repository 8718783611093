import api, { AssetAccount, assetAccountTypeDisplay } from '@api';
import { Edit, RemoveCircleOutline } from '@mui/icons-material';
import {
    Button, Paper, Tooltip, Typography
} from '@mui/material';
import { formatCurrency, formatDate, useParams } from '@tsp-ui/core';
import {
    IconButton, LabelGroup, LabeledValue, usePageMessage
} from '@tsp-ui/core/components';
import { LoanDetailPageParams } from '@views/loans/LoanDetailPage';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './AssetAccountCard.module.scss';
import { AssetAccountsContext } from './AssetAccountsSection';


interface AssetAccountCardProps {
    assetAccount: AssetAccount;
}

export default function AssetAccountCard({ assetAccount }: AssetAccountCardProps) {
    const { accountID: clientId, loanID: loanId } = useParams<LoanDetailPageParams>();
    const pageMessage = usePageMessage();
    const [ deleteLoading, setDeleteLoading ] = useState(false);
    const { setAssetAccounts, setAssetSummary } = useContext(AssetAccountsContext);

    async function deleteAssetAccount(assetAccountId: string) {
        setDeleteLoading(true);

        try {
            await api.assetAccount.deleteAssetAccount(clientId, loanId, assetAccountId);

            setAssetAccounts((accounts) => accounts.filter((account) => account.id !== assetAccountId));

            // refresh asset summary
            setAssetSummary(await api.assetAccount.getAssetSummary(clientId, loanId));

            pageMessage.success('Asset account deleted');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while deleting asset account', error);
        }

        setDeleteLoading(false);
    }
    return (
        <Paper
            variant="outlined"
            className={styles.root}
        >
            <div className={styles.accountInfo}>
                <Typography fontWeight="500">
                    {assetAccount.institutionName}
                </Typography>

                <Typography variant="body2">
                    {assetAccount.accountIdentifier}
                </Typography>

                <Typography
                    variant="body2"
                    className={styles.accountType}
                >
                    {assetAccount.type ? assetAccountTypeDisplay[assetAccount.type] : '--'}
                </Typography>

                {assetAccount.type && (
                    <Typography variant="body2">
                        {formatDate(assetAccount.statementDate)}
                    </Typography>
                )}
            </div>

            <div>
                <div className={styles.iconButtons}>
                    <Tooltip title="Delete">
                        <IconButton
                            loading={deleteLoading}
                            size="small"
                            onClick={() => deleteAssetAccount(assetAccount.id)}
                        >
                            <RemoveCircleOutline color="error" />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Edit">
                        <IconButton
                            size="small"
                            component={Link}
                            to={`asset-account/${assetAccount.id}`}
                        >
                            <Edit color="secondary" />
                        </IconButton>
                    </Tooltip>
                </div>

                {assetAccount.type ? (
                    <LabelGroup>
                        <LabeledValue
                            label="Account balance:"
                            value={formatCurrency(assetAccount.accountBalance)}
                            classNames={{ value: styles.rightAlign }}
                        />

                        <LabeledValue
                            label="Qualifying balance:"
                            value={formatCurrency(assetAccount.qualifyingBalance)}
                            classNames={{ value: styles.rightAlign }}
                        />

                        <LabeledValue
                            label="Months of statements:"
                            value={assetAccount.statementMonths}
                        />
                    </LabelGroup>
                ) : (
                    <Button
                        size="small"
                        component={Link}
                        to={`asset-account/${assetAccount.id}`}
                    >
                        Enter account info
                    </Button>
                )}
            </div>
        </Paper>
    );
}
