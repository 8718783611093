import { UnderwritingQuestionConfig, UnderwritingQuestionResponse } from '@api';
import { Edit, RemoveCircleOutline } from '@mui/icons-material';
import { DialogContent, Paper } from '@mui/material';
import {
    Button, Dialog, DialogActions, IconButton, replaceItemByKey
} from '@tsp-ui/core';
import { Markdown } from '@views/components/Markdown';
import {
    useContext, useEffect, useMemo, useState
} from 'react';


import { UnderwritingCategoriesContext } from '../UnderwritingCategories';
import { UnderWritingStepContext } from '../UnderwritingStepSection';
import { UnderwritingQuestionCardProps, questionTypeToComponentMap } from '../underwriting-common';

import styles from './ForeachQuestionCard.module.scss';
import { UnderwritingQuestionForm } from './FormQuestionCard';


export function ForeachQuestionCard({ questionConfig }: UnderwritingQuestionCardProps) {
    const {
        questionIdsToDisplay, questionResponses, questionConfigs, setQuestionResponses
    } = useContext(UnderWritingStepContext);
    const { underwritingData } = useContext(UnderwritingCategoriesContext);
    const [ addEditDialogOpen, setAddEditDialogOpen ] = useState(false);
    const [ responseToEdit, setResponseToEdit ] = useState<UnderwritingQuestionResponse>();

    // each one of these responses to the main foreach question represents a foreach entity
    // each response will have an entityId, and fieldValues
    const forEachResponses = questionResponses.filter(response => response.questionId === questionConfig.id);

    return (
        <>
            <Button onClick={() => setAddEditDialogOpen(addDialogOpen => !addDialogOpen)}>
                {questionConfig.addButtonText || 'Add Item'}
            </Button>

            {/* For each entity, render a card with all of the subQuestions */}
            {forEachResponses?.map((forEachResponse, index) => {
                const entityId = forEachResponse.entityId || forEachResponse.frontEndEntityId;
                const subQuestionConfigs = questionConfigs.filter(
                    config => config.parentQuestionId === questionConfig.id
                );

                const valuesToInject = {
                    index,
                    ...forEachResponse.fieldValues?.reduce((acc, fieldResponse) => {
                        const fieldConfig = questionConfig.fields?.find(field => field.id === fieldResponse.fieldId);
                        return {
                            ...acc,
                            // *IMPORTANT*
                            // This is where the values are being injected into the markdown.
                            // You can either use the {{ dataSourceId}} or {{ fieldId }} in
                            // the question text to utilize this injection
                            [fieldConfig!.dataSourceId || fieldConfig!.id]: fieldResponse.value
                        };
                    }, {}),
                    ...underwritingData
                };

                return (
                    <Paper
                        key={`${questionConfig.id}-${entityId}`}
                        variant="outlined"
                        className={styles.forEachQuestionCard}
                    >
                        <div className={styles.header}>
                            <Markdown valuesToInject={valuesToInject}>
                                {questionConfig.text}
                            </Markdown>

                            <div className={styles.iconButtons}>
                                <IconButton
                                    size="small"
                                    tooltip="Delete"
                                    onClick={() => {
                                        setQuestionResponses(
                                            questionResponses.filter(response => response.id !== forEachResponse.id)
                                        );

                                        /* eslint-disable max-len */
                                        // TODO post demo: when we implement editing steps after they have already been submitted we will need to delete the response from the backend as well.
                                        // For now, we can just remove from state because the response will not have been submitted yet

                                        // TODO delete loading
                                        // try {
                                        //     await api.underwriting.deleteUnderwritingQuestionResponse('clientId', 'loanId', 'underwritingStepId', forEachResponse.id);

                                        //     setQuestionResponses(
                                        //         questionResponses.filter(response => response.id !== forEachResponse.id)
                                        //     );
                                        // } catch (error) {
                                        // // TODO page message
                                        // }
                                        /* eslint-enable max-len */
                                    }}
                                >
                                    <RemoveCircleOutline color="error" />
                                </IconButton>

                                <IconButton
                                    size="small"
                                    tooltip="Edit"
                                    onClick={() => {
                                        setResponseToEdit(forEachResponse);
                                        setAddEditDialogOpen(true);
                                    }}
                                >
                                    <Edit color="secondary" />
                                </IconButton>
                            </div>
                        </div>

                        {subQuestionConfigs.map(subQuestionConfig => {
                            const subQuestionResponse = questionResponses.find(
                                response => {
                                    const entityId = response.entityId || response.frontEndEntityId;
                                    return response.questionId === subQuestionConfig.id
                                        && (entityId === forEachResponse.entityId
                                        || entityId === forEachResponse.frontEndEntityId);
                                }
                            );

                            const UnderwritingQuestionCard = questionTypeToComponentMap[subQuestionConfig.type];
                            return questionIdsToDisplay.includes(`${subQuestionConfig.id}-${entityId}`)
                                ? (
                                    <UnderwritingQuestionCard
                                        key={subQuestionConfig.id}
                                        questionConfig={subQuestionConfig}
                                        questionResponse={subQuestionResponse || {
                                            id: '',
                                            entityId: forEachResponse.entityId,
                                            frontEndEntityId: forEachResponse.frontEndEntityId,
                                            questionId: subQuestionConfig.id
                                        }}
                                    />
                                )
                                : null;
                        })}
                    </Paper>
                );
            })}

            {addEditDialogOpen && (
                <ForEachItemDialog
                    open
                    setOpen={setAddEditDialogOpen}
                    questionConfig={questionConfig}
                    responseToEdit={responseToEdit}
                    setResponseToEdit={setResponseToEdit}
                />
            )}
        </>
    );
}

interface ForEachItemDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    questionConfig: UnderwritingQuestionConfig;
    responseToEdit?: UnderwritingQuestionResponse;
    setResponseToEdit: (response?: UnderwritingQuestionResponse) => void;
}

function ForEachItemDialog({
    open, setOpen, questionConfig, responseToEdit, setResponseToEdit
}: ForEachItemDialogProps) {
    const { updateQuestionIdsToDisplayAndSideEffects, setQuestionResponse } = useContext(UnderWritingStepContext);

    const defaultValues = useMemo<UnderwritingQuestionResponse>(() => ({
        // these ids are so we can keep track of the responses on the frontend prior to submitting the step
        id: '',
        frontEndEntityId: new Date().toISOString(),
        questionId: questionConfig.id,
        fieldValues: questionConfig.fields?.map(fieldConfig => ({
            fieldId: fieldConfig.id,
            value: '',
            id: ''
        })) || []
    }), [ questionConfig.id, questionConfig.fields ]);

    // This state is just used for the form fields within this component
    const [ questionResponseState, setQuestionResponseState ] = useState<UnderwritingQuestionResponse>(
        responseToEdit || defaultValues
    );

    useEffect(() => {
        setQuestionResponseState(responseToEdit ? responseToEdit : defaultValues);
    }, [ defaultValues, responseToEdit ]);

    function onClose() {
        setResponseToEdit(undefined);
        setOpen(false);
    }

    // Return true if all required fields in question.fields have corresponding values
    const containsAllRequiredItems = questionConfig.fields?.every(
        // if isRequired is undefined, it is assumed to be required
        field => (!(field.isRequired === false) ? questionResponseState.fieldValues?.find(
            fieldValue => fieldValue.fieldId === field.id
        )?.value : true)
    );

    return (
        <Dialog
            title={questionConfig.addButtonText}
            open={open}
            onClose={onClose}
        >
            <DialogContent className={styles.forEachQuestionDialog}>
                <UnderwritingQuestionForm
                    questionConfig={questionConfig}
                    questionResponse={questionResponseState}
                    getOnChange={fieldResponse => (newValue) => {
                        const existingFieldResponse = questionResponseState.fieldValues!.find(
                            fieldValue => fieldValue.fieldId === fieldResponse.fieldId
                        );

                        setQuestionResponseState({
                            ...questionResponseState,

                            // check if field exists in response
                            // if so, replace it
                            // othersiee, add a new field response to field values in the question response
                            fieldValues: existingFieldResponse
                                ? replaceItemByKey(
                                    questionResponseState.fieldValues!,
                                    {
                                        ...fieldResponse,
                                        value: newValue
                                    },
                                    'fieldId'
                                )
                                : [
                                    ...questionResponseState.fieldValues!, {
                                        ...fieldResponse,
                                        value: newValue
                                    }
                                ]
                        });
                    }}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>
                    Cancel
                </Button>

                <Button
                    onClick={() => {
                        // sets the response in the parent/master state
                        const updatedResponses = setQuestionResponse(questionResponseState);
                        updateQuestionIdsToDisplayAndSideEffects(updatedResponses);

                        onClose();
                    }}
                    variant="contained"
                    disabled={!containsAllRequiredItems}
                    tooltip={containsAllRequiredItems ? undefined : 'Please fill out all required fields'}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
